<template>
  <div class="body">
    <Navigation />

    <!-- Hero Banner-->
    <section class="hero-home bg-cover bg-center bg-image-cover" style="">
      <div class="container z-index-20 py-3">
        <div class="row text-white">
          <div class="col-lg-8">
            <h2 class="text-uppercase mb-0 text-shadow">
              Association Louzolo Amour OPH
            </h2>
            <p class="h5 my-4 text-shadow">
              Association de Bienfaisance à Caractère Spirituel
            </p>
            <p class="">
              <strong>
                Elle est créée par Papa Guy Émile LOUFOUA CETIKOUABO, le
                Président Fondateur, Grand Maitre de l’OPH, l’Esprit de Vérité
              </strong>
            </p>
            <router-link :to="{name: 'asla'}" class="btn btn-light text-primary px-5"
              >En savoir plus
              </router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- Info Section-->
    <section class="info p-0 bg-light">
      <div class="container">
        <div class="card border-0">
          <div class="card-body py-5 border-top border-2 border-primary">
            <div class="row gy-4">
              <div class="col-lg-4 border-end">
                <div class="px-4">
                  <p class="text-primary mb-0 fw-normal">
                    Vérité Divine de l'Heure
                  </p>
                  <h3 class="h5">Guy Emile L.C ne mourra point</h3>
                  <p class="text-muted mb-0 text-sm">
                    Guy Emile Loufoua Cetikouabo est établit sur terre
                    d'éternité en éternité pour y régner éternellement depuis le
                    17 octobre 2004.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 border-end">
                <div class="px-4">
                  <p class="text-primary mb-0 fw-normal">
                    Vérité Divine de l'Heure
                  </p>
                  <h3 class="h5">
                    ASLA-OPH, 2<sup>e</sup> Nation Sainte de Dieu
                  </h3>
                  <p class="text-muted mb-0 text-sm">
                    L'ASLA-OPH est la 2<sup>e</sup> nation sainte de Dieu
                    proclammée depuis le 1<sup>er</sup> janvier 2000 à minuit (Matthieu 21:
                    43).
                  </p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="px-4">
                  <p class="text-primary mb-0 fw-normal">
                    Vérité Divine de l'Heure
                  </p>
                  <h3 class="h5">
                    Kari-Kari, la nouvelle demeure de l'Eternel Dieu
                  </h3>
                  <p class="text-muted mb-0 text-sm">
                    Le 20 mai 1992 en boule de feu, l'étoile de David a quitté
                    Béthléem pour s'implanter définitivement à Kibossi, au Mont
                    KARI-KARI.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- About Section-->
    <section class="about bg-light pt-0">
      <div class="container text-center">
        <h2 class="text-uppercase lined lined-center">
          <span class="text-primary">Bienvenu sur notre Site </span>
        </h2>
        <p class="text-muted">
          L'unique canal web officiel de l'Association Louzolo Amour OPH
        </p>
        <div class="row pt-5 gy-4 gx-5">
          <div class="col-lg-4">
            <!-- <i class="fas fa-3x text-primary mb-3 fa-stethoscope"></i> -->
            <img src="../../assets/img/aOc.jpg" class="mb-3" width="150" alt=""/>
            <h2 class="h5">Des Vérités Divines de l'Heure</h2>
            <p class="text-muted mb-0">
                Remémorez-vous la mémoire sur les dates clés revélées par Papa Guy Emile LOUFOUA CETIKOUABO, Dieu.
            </p>
          </div>
          <div class="col-lg-4">
            <!-- <i class="fas fa-3x text-primary fa-solid fa-object-union"></i> -->
            <!-- <i class="fas fa-3x text-primary mb-3 fa-microscope"></i> -->
            <img src="../../assets/img/aOc.jpg" class="mb-3" width="150" alt=""/>
            <i class="fas fa-3x text-primary mb-3 fa-sharp fa-solid fa-object-union"></i>
            <h2 class="h5">Des notes circulaires</h2>
            <p class="text-muted mb-0">
              Recherchez, trouvez et téléchargez des notes circulaires pour vous
              rappeler des directives de Papa Guy Emile LOUFOUA CETIKOUABO
            </p>
          </div>
          <div class="col-lg-4">
            <!-- <i class="fas fa-3x text-primary mb-3 fa-syringe"></i> -->
            <img src="../../assets/img/aOc.jpg" class="mb-3" width="150" alt=""/>
            <h2 class="h5">Des articles</h2>
            <p class="text-muted mb-0">
              Surfez et profitez 24h/24 de nos articles abordant les sujets de toute catégorie 
              pour votre éléation spirituelle
            </p>
          </div>
        </div>
      </div>
    </section>

    <!-- Statistics Section-->
    <section class="statistics py-5 bg-primary">
      <div class="container text-white">
        <div class="row gy-4">
          <div class="col-lg-4 col-md-6">
            <div
              class="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <h2 class="text-lg me-3 mb-0">{{ counterNotes }}</h2>
              <p class="lead lh-sm mb-0">
                Notes <br />
                Circulaires
              </p>
            </div>
          </div>
          <!-- <div class="col-lg-3 col-md-6">
            <div
              class="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <h2 class="text-lg me-3 mb-0">79</h2>
              <p class="lead lh-sm mb-0">
                Messages <br />
                transcrits
              </p>
            </div>
          </div> -->
          <div class="col-lg-4 col-md-6">
            <div
              class="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <h2 class="text-lg me-3 mb-0">{{ counterArticle }}</h2>
              <p class="lead lh-sm mb-0">
                Articles <br />
                de blog
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div
              class="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <h2 class="text-lg me-3 mb-0">{{ counterVDH }}</h2>
              <p class="lead lh-sm mb-0">
                Vérités Divines <br />
                de l'Heure
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Prices Section-->
    <section class="prices" id="actu">
      <div class="container">
        <div class="row mb-5 typing-animatio">
          <!-- <div class="col-lg-4">
            <marquee behavior="alternate" direction="right">
              <h2 class="h5 bg-breaking p-4 text-white">Breaking News</h2>
            </marquee>
          </div>
          <div class="col-lg-8">
            <div class="marquee-rtl">
                <div>Le message que l'on veut voir défilé horizontalement...</div>
            </div>
          </div> -->
        </div>

        <ul
          class="nav nav-pills mb-3 flex-column flex-sm-row text-center"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item flex-sm-fill">
            <a
              class="nav-link active"
              id="pills-cosmetic-tab"
              data-bs-toggle="pill"
              href="#pills-cosmetic"
              role="tab"
              aria-controls="pills-cosmetic"
              aria-selected="true"
              >Dernier article</a
            >
          </li>
          <li class="nav-item flex-sm-fill">
            <a
              class="nav-link"
              id="pills-Medications-tab"
              data-bs-toggle="pill"
              href="#pills-Medications"
              role="tab"
              aria-controls="pills-Medications"
              aria-selected="false"
              >Article le plus consulté</a
            >
          </li>
          <li class="nav-item flex-sm-fill">
            <a
              class="nav-link"
              id="pills-Physician-tab"
              data-bs-toggle="pill"
              href="#pills-Physician"
              role="tab"
              aria-controls="pills-Physician"
              aria-selected="false"
              >Dernière VDH</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane py-4 fade show active"
            id="pills-cosmetic"
            role="tabpanel"
            aria-labelledby="pills-cosmetic-tab"
          >
            <div class="row">
              <div class="col-lg-6"  v-for="(article, index) in latestTitle" :key="index">
                <div class="d-flex align-items-center mb-2">
                    <h6 class="mb-0">{{ article.category.libelle }}</h6>
                    <div class="dotted-separator mx-3 flex-grow-1 my-0"></div>
                    <h6 class="text-primary mb-0">
                        <router-link :to="{ name: 'single-article', query:{article: article.id, titre: article.slug.toLowerCase()}}">
                            <i class="fa-solid fa-eye"></i>
                        </router-link>
                    </h6>
                </div>
                <p class="text-muted text-sm">
                    <router-link :to="{ name: 'single-article', query:{article: article.id, titre: article.slug.toLowerCase()}}">
                        <b>{{ article.title }}</b>
                    </router-link>
                </p>
              </div>
            </div>
          </div>
          <div
            class="tab-pane py-4 fade"
            id="pills-Medications"
            role="tabpanel"
            aria-labelledby="pills-Medications-tab"
          >
            <div class="row">
              <div class="col-lg-6" v-for="(article, index) in popularArticle" :key="index">
                <div class="d-flex align-items-center mb-2">
                  <h6 class="mb-0">Consulté {{ article.views_count }} fois</h6>
                  <div class="dotted-separator mx-3 flex-grow-1 my-0"></div>
                  <h6 class="text-primary mb-0">
                    <router-link :to="{ name: 'single-article', query:{article: article.id, titre: article.slug.toLowerCase()}}">
                        <i class="fa-solid fa-eye"></i>
                    </router-link> 
                  </h6>
                </div>
                <p class="text-muted text-sm">
                    <router-link :to="{ name: 'single-article', query:{article: article.id, titre: article.slug.toLowerCase()}}">
                        <b>{{ article.title }}</b>
                    </router-link>
                </p>
              </div>
            </div>
          </div>
          <div
            class="tab-pane py-4 fade"
            id="pills-Physician"
            role="tabpanel"
            aria-labelledby="pills-Physician-tab"
          >
            <div class="row">
              <div class="col-lg-6" v-for="(vdh, key) in latestVDH" :key="key">
                <div class="d-flex align-items-center mb-2">
                    <h6 class="mb-0">{{ vdh.reference }}</h6>
                    <div class="dotted-separator mx-3 flex-grow-1 my-0"></div>
                    <h6 class="text-primary mb-0">
                        <!-- <i class="fa-solid fa-eye"></i> -->
                        <router-link :to="{ name: 'singlevdh', query:{vdh: vdh.id, titre: vdh.slug.toLowerCase()} }"> 
                            <i class="fa-solid fa-eye"></i>
                        </router-link>
                    </h6>
                </div>
                <p class="text-muted text-sm">
                    <router-link :to="{ name: 'singlevdh', query:{vdh: vdh.id, titre: vdh.slug.toLowerCase()} }"> 
                        <b>{{ vdh.evenement }}</b>
                    </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="departments pb-0 bg-light">
      <div class="container">
        <div class="row align-items-end">
          <div class="col-lg-5 d-none d-lg-block">
            <img class="img-fluid" src="../../assets/img/gm.jpg" alt="doctor" />
          </div>
          <div class="col-lg-7 pb-5 mb-5">
            <h2 class="text-uppercase lined">
              Mot du <span class="text-primary">Délégué Permanent</span>
            </h2>
            <p class="mb-5">
              <span class="text-muted"
                >Discours du Délégué Permanent du Président Fondateur de
                l'Association Louzolo Amour OPH à l'occasion de la célébration
                des quadruples fêtes successives de décembre 2022.</span
              >
              <br />
              <strong>Enoncé des objectifs de l'an 2023.</strong>
            </p>
            <div class="accordion pb-5" id="accordion">
              <div class="accordion-item border-0 rounded-0 mb-2">
                <div class="accordion-header p-0 border-0" id="headingOne">
                  <h6 class="mb-0">
                    <a
                      class="accordion-button py-3 px-4"
                      data-bs-toggle="collapse"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <img src="../../assets/img/aOc.jpg" class="" width="50" alt=""/> &nbsp;
                      Chers Frères et soeurs en Ceti,
                    </a>
                  </h6>
                </div>
                <div
                  class="collapse show"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div class="p-4">
                    <p class="text-muted" style="">
                        (...) je viens vous transmettre l’État d’Esprit et la Nature
                        de Notre Très Cher Père Céleste, le Dieu Créateur de l’univers,
                        Papa <strong>Guy Émile LOUFOUA CETIKOUABO</strong>, <strong>Grand Maître de l’OPH</strong>,
                        Président
                        Fondateur de l’ASLA-OPH afin de vivre l’Amour Éternel, l’Allégresse
                        Éternelle dans la Paix Éternelle, tout au long de cette année 2022
                        que nous avons débuté la très haute spiritualité sans faille dans
                        l’ASLA-OPH.
                    </p>
                    <p class="text-muted mb-0" style="">
                        De grâce, veuillez donc recevoir, les salutations qui renferment
                        tous les meilleurs voeux, qui feront que notre joie soit parfaite
                        durant toute l’année 2023.
                    </p>

                    <router-link :to="{name: 'mot-dp'}" class="btn btn-primary w-100">
                        <i class="fa-solid fa-plus"></i> Lire la suite
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- A propos de asla-oph-->
    <section class="services">
      <div class="container">
        <div class="text-center">
          <h2 class="text-uppercase lined lined-center">
            A propos de <span class="text-primary">l'asla oph</span>
          </h2>
        </div>
        <div class="row gy-4">
          <div class="col-md-6">
            <img src="../../assets/img/asla-embleme.jpg" width="100%" alt="" />
          </div>
          <div class="col-md-6">
            <p style="">
                - L’Association Louzolo « Amour » - OPH est une Association 
                de bienfaisance à caractère spirituel, régie par la loi de 1901. 
                Elle était anciennement enregistrée au Ministère de l’Intérieur 
                de la République du Congo sous le récépissé numéro 
                884/83/MINT/SGAT/DEC/SAG/ du 15 Avril 1983, et réactualisée 
                sous le récépissé numéro 004/015/MID/DGAT/DER/SAG du12/03/15 ce, 
                conformément aux textes fondamentaux révisés en février 2001
            </p>

            <p style="">
                - Elle a est créée par Papa Guy Émile LOUFOUA CETIKOUABO, 
                le Président Fondateur, Grand Maitre de l’OPH, l’Esprit de Vérité. 
                Il est en retraite spirituelle à Kibossi depuis 1988 selon la volonté 
                divine.
            </p>

            <p class="en_savoir_plus_top">
                <!-- <router-link :to="{ name: 'asla' }" class="btn btn-primary">En savoir plus</router-link> -->
            </p>
            
          </div>
          <router-link :to="{ name: 'asla' }" class="btn btn-primary float-right">En savoir plus</router-link>
        </div>
      </div>
    </section>

    <!-- footer -->
    <Footer />
  </div>
</template>

<script>
import Navigation from "../Navigation";
import Footer from "../Footer";
import axios from 'axios';

export default {
  components: {
    Navigation,
    Footer,
  },

  data(){
    return{
        title: "ACCUEIL",
        typeValue: "",
      typeStatus: false,
      displayTextArray: [],
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,

      breaking: [],
      counterArticle: null,
      counterNotes: null,
      counterVDH: null,

      latestTitle: [],
      popularArticle: [],
      latestVDH: []
    }
  },

  methods: {
    // typeText() {
    //   if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
    //     if (!this.typeStatus) this.typeStatus = true;
    //     this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
    //       this.charIndex
    //     );
    //     this.charIndex += 1;
    //     setTimeout(this.typeText, this.typingSpeed);
    //   } else {
    //     this.typeStatus = false;
    //     setTimeout(this.eraseText, this.newTextDelay);
    //   }
    // },
    // eraseText() {
    //   if (this.charIndex > 0) {
    //     if (!this.typeStatus) this.typeStatus = true;
    //     this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
    //       0,
    //       this.charIndex - 1
    //     );
    //     this.charIndex -= 1;
    //     setTimeout(this.eraseText, this.erasingSpeed);
    //   } else {
    //     this.typeStatus = false;
    //     this.displayTextArrayIndex += 1;
    //     if (this.displayTextArrayIndex >= this.displayTextArray.length)
    //       this.displayTextArrayIndex = 0;
    //     setTimeout(this.typeText, this.typingSpeed + 1000);
    //   }
    // },
    
    async getBreaking(){
        await axios.get('breaking')
        .then(res=>{
            for(let i = 0; i <= res.data.length; i++){
                // console.log(res.data[i].libelle, i)
                // this.displayTextArray = res.data[i].libelle
                // console.log(this.displayTextArray)
            }
            
            this.displayTextArray = res.data
            // console.log(res.data)
        })
    },

    async getCounter(){
        await axios.get('get-counter')
        .then(res=>{
            this.counterArticle = res.data.Article
            this.counterNotes = res.data.Notes
            this.counterVDH = res.data.VDH
        })
    },

    async getLatestArticle(){
        await axios.get('blog/latest-article')
        .then(res=>{
            this.latestTitle = res.data
        })
    },

    async getPopularArticle(){
        await axios.get('blog/popular-article')
        .then(res=>{
            this.popularArticle = res.data
        })
    },

    async getLatestVDH(){
        await axios.get('get-last-vdh')
        .then(res=>{
            this.latestVDH = res.data
        })
    },
  },

    created() {
        setTimeout(this.typeText, this.newTextDelay + 200);
    },

    mounted(){
        this.getBreaking()
        this.getCounter()
        this.getLatestArticle()
        this.getPopularArticle()
        this.getLatestVDH()
    },
};
</script>

<style scoped>

.body {
    overflow-x:hidden;
}
.bg-image-cover {
  background-image: url("../../assets/img/banniere2.jpg");
  /* background-size: 100%; */
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}

.en_savoir_plus_top {
  /* margin-top: 90px; */
}

.bg-breaking {
  background-color: #8f4f4a;
}

@media (min-width: 480px) and (max-width: 767px){
    .bg-image-cover{
        /* display: none; */
        /* background-size: cover !important; */
    }
}

</style>