<template>
    <div>
        <div class="container">
            <div class="">
                <div class="">
                    <div align="center">
                        <img src="../assets/img/404.png" width="300" class="img-thumbnail" alt="">
                        <h1 style="font-size: 20px" class="text-uppercase mt-3">
                            La page que vous essayez de conculter a été soit déplacée, soit supprimée.
                        </h1>

                        <p>
                            Sans détour, il n'est point de voyage ... Retournez en lieu sûr
                        </p>
                        <router-link :to="{name: 'Index'}" class="btn btn-primary mb-2">Accueil</router-link>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
  import Navigation from "./Navigation.vue";
  import Footer from "./Footer.vue";
  import Hero from "./Hero.vue";
  
  export default {
    components: {
      Navigation,
      Hero,
      Footer,
    },
  };
  </script>

  <style scoped>
    .img-responsive{
        max-width: 100%
    }
  </style>