<template>
  <div>
    <Navigation />
    <Hero
      title="PORTEE HISTORIQUE ET SPIRITUELLE DU MONT DES OLIVIERS"
      ComponentTitle="A PROPOS"
    />
    <section class="container">
      <h2 class="text-uppercase lined lined-center" style="margin-top: -35px">
        <span class="text-primary">
          Portée historique et spirituelle du mont des oliviers
        </span>
      </h2>

      <section class="container">
        <h4>Au temps Messianique,</h4>
        <p class="text-justify">
          Le Mont des oliviers est une colline située par-delà la vallée du
          Cedron, à l'Est de Jérusalem. Elle s'élève au plus de 600m au dessus
          de la ville. Il fut appelé ainsi car il y avait des oliveraies qui le
          couvraient . Matthieu 26:36 et Marc 14:32 l'appellent «Jardin de
          Gethsemané» qui signifie «Pressoir des olives». C'était un lieu
          habituel de rencontre du Seigneur Jésus avec ses disciples (Jean
          18:2). Il était de ce fait , un lieu de méditation et de prière pour
          le Seigneur Jésus ( Matthieu 26:36). Sentant sa mort prochaine, le
          Seigneur Jésus alla avec ses disciples à cette colline pour prier ;
          c'est à cette occasion qu'il retira Jean , Jacques et Pierre afin que
          ces derniers veille avec lui en cette période où son âme était
          affligée à tel enseigne qu'il demanda à Dieu son Père à trois (03)
          reprises si c'était possible d'éloigner cette coupe de souffrance
          qu'il devait boire ( Cf Matthieu 26 :36-46). C'était alors là le lieu
          même de son arrestation ainsi , débuta sa lourde passion qui le
          conduisit droit à la crucifixion. Cette Montagne fut donc l'endroit
          mieux choisis par le Seigneur Jésus où il parlait ouvertement à ses
          disciples. C'est d'ailleurs sur ce même lieu que le Seigneur Jésus à
          appris à ses disciples comment prier notamment la célèbre prière du
          PATER NOSTER( Luc 11:1-4). Aussi, les appela t-il a demeurer vigilant
          d'où la parabole des dix vierges et celle des trois serviteurs (
          Matthieu 24:1-46). Le Mont des oliviers occupe donc une place de choix
          dans la vie du Seigneur Jésus étant donné que c'est de là que Jésus
          monta au ciel après être apparu à ses disciples pendant 40 jours ,
          dans son corps de ressuscité ( Actes 1:1-3;9;12) et c'est au même
          endroit que Dieu le Père viendra pour gouverner la terre entière ( Cf
          Zacharie 14:1-9).
        </p>

        <h4>Au temps de Dieu le Père,</h4>

        <p class="text-justify">
          Puisque dans le plan divin , il était prévu que celui qui est Dieu et
          Père se manifeste sur terre ( Matthieu 24:36-41; Jean 16:10-15), venu
          sur terre pour débuter à bon escient sa mission de rassembler , c'est
          le 31 décembre 1969 que le Grand Maître de l'OPH Papa Guy Emile
          LOUFOUA CETIKOUABO alla à Pointe Noire la capitale économique du Congo
          Brazzaville où il commença son oeuvre . Tout comme son fils Jésus, Guy
          Emile LOUFOUA CETIKOUABO se choisit des collaborateurs qui, cependant,
          lui menèrent la vie très difficile. Voici ce que ses détracteurs
          disaient « GRAND MAITRE aujourd’hui samedi, tes intestins seront
          dehors et broyés. Chacun avait à la main un coupe-coupe ou un bâton.
          Le groupe se composait au moins de 30 (trente)** personnes, tous des
          garçons. Je continuais à avancer en passant au milieu d’eux. Après
          quelques dizaines de mètres, nous fûmes arrivés à un village mon ami
          et moi. Nous nous étions arrêtés pour attendre le car afin de nous
          rendre à POINTE-NOIRE. Ce fut un samedi. Compte tenu des menaces que
          proférait la foule des brigands qui en voulaient à ma vie, je ne
          pouvais plus continuer à faire la distance à pied. Que racontaient les
          gens à haute voix qui constituaient la foule. Aujourd’hui EMILE ne
          repartira plus jamais vivant à LOUDIMA là où il a l’habitude de fuir.
          Nous attendons notre chef qui vient derrière pour accomplir notre
          mission. Tout ceci se disait à haute voix. Aujourd’hui c’est fini, ta
          vie est terminée. Tu nous as trop menti et volé des sacrifices>>( Cf
          Document de la Passion du Grand Maître de l'OPH).
          <br />
          <br />
          Triste et abbatu , de ce que ses collaborateurs avaient organisés avec
          la foule des brigands ce Samedi 7 Octobre 1979, le 8 Octobre 1979 le
          Grand Maître de l'OPH retourna à Loudima et y arriva à 13h. Étant
          donné qu'il y avait des invités dans sa maison , il alla consulter le
          Frère MAMPOUYA SAMUEL GOTHARD qui lui fit préparer la chambre d'amis
          que ne se fermait que de l'extérieur. Il le raconte comme suit «Il me
          fit préparer la chambre d’amis. Cette chambre ne se fermait que de
          l’extérieur ; alors quand je partis me coucher, le Fr. Gothard
          m’enferma, partit avec la clé et promit de m’ouvrir la porte le
          lendemain matin avant d’aller au travail. A cette époque il était le
          directeur des deux écoles de Loudima-gare. La chambre dans laquelle
          j’étais avait une fenêtre condamnée, elle ne s’ouvrait pas, et il n’y
          avait que cette porte qui ne se fermait que de l’extérieur. Une fois
          enfermé dans la chambre, je déposai mon sac de voyage sur une chaise
          qui y était puis je m’assis à même le lit. A cet instant même, des
          pensées de tristesse m’envahirent, une terrible tristesse m’envahit.
          Je me disais : « ah ! Des gens à qui je n’ai fait que du bien !
          Aujourd’hui ils veulent me tuer ! » Je pensais, je m’attristais>>(
          Message du 16 Avril 2000).
          <br />
          <br />
          Alors qu'il pensait encore , voilà trois (03) anges apparurent à 20h
          du même jour pour le prendre et l'emmener dans les cieux où il
          séjourna pendant trois (03)semaines et trois(03) jours et il était
          revenu avec son corps de gloire ce qui fait qu'il a fallu qu'il reste
          encore dans la maison pendant un mois et demi sans être en contact
          avec les humains. Et le 22 décembre 1979 il repartira à Pointe Noire
          pour affronter la grande passion ( du 22 décembre 1979 au 8 Janvier
          1980). Des années s'écoulèrent sans que l'humanité sache réellement ce
          qui s'était passé lors de sa disparition à Loudima .c'est finalement
          le 16 Avril 2000 qu'il va parlé officiellement de son enlèvement au
          ciel par trois( 03) anges à Loudima Gare et le 21 Avril 2001 Il
          institua au sein de son Association, une Journée Internationale
          d'action de grâce de sa disparition à Loudima communément appelé Fête
          de l'enlèvement au ciel du Grand Maître de l'OPH et de son retour sur
          terre .Ainsi , Dans la note 110 du 23 Septembre 2001, la section de
          Loudima Gare est debaptisée : Section Inter-continentale du MONT DES
          OLIVIERS. Enfin, En Décembre 2004 par note numéro 93, le Grand Maître
          de l'OPH décrète ce lieu comme étant un Site touristique en ces termes
          «Cette cité de Loudima est appelée, vous vous en doutez à accueillir
          plusieurs visiteurs et touristes du monde entier, noirs comme blancs».
          <br />
          Dans cette même note, le Mont des Oliviers est décrété deuxième cité
          trois fois sainte après
          <router-link to=""><strong>le Mont Kari-Kari.</strong></router-link
          >.
        </p>
      </section>
    </section>

    <Footer />
  </div>
</template>

<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
  },
};
</script>

<style scoped>
.text-justify {
  text-align: justify;
}
</style>