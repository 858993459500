<template>
    <div>
        <Navigation />
        <Hero 
            title="Mot du Délégué Permanent du Président Fondateur de l'ASLA-OPH" 
            ComponentTitle="Enoncé objectifs" 
        />
        <section class="container">
            <h2 class="text-uppercase lined lined-center" style="margin-top: -35px">
                <span class="text-primary">Enoncé des objectifs 2023</span>
            </h2>

            <div>
                <p class="justify mt-5">
                    MBOTÉ, BONJOUR, BONNE FÊTE, BOUANANA, en cette journée solennelle du 25 décembre
                    2022 ; <br>
                    MBOTÉ, BONJOUR, BONNE FÊTE, BOUANANA, tout au long de cette année 2022 qui s’achève
                    ; <br>
                    MBOTÉ, BONJOUR, BONNE FÊTE, BOUANANA, en début d’année 2023 ; <br>
                    MBOTÉ, BONJOUR, BONNE FÊTE, BOUANANA, d’éternité en éternité.
                </p>

                <p class="justify">
                    À travers cette divine salutation, je viens vous transmettre
                    l’État d’Esprit et la Nature, de Notre, Très Cher Père Céleste,
                    le Dieu Créateur de l’univers, <strong>Papa Guy Émile LOUFOUA CETIKOUABO</strong>,
                    Grand Maître de l’OPH, Président Fondateur de l’ASLA-OPH afin de vivre
                    l’Amour Éternel, l’Allégresse Éternelle dans la Paix Éternelle, tout au long
                    de cette année 2022 que nous avons débuté la très haute spiritualité sans
                    faille dans l’ASLA-OPH.
                </p>

                <p class="justify">
                    De grâce, veuillez donc recevoir, les salutations qui renferment
                    tous les meilleurs voeux, qui feront que notre joie soit parfaite
                    durant toute l’année 2023.
                </p>

                <p><strong>Frères et soeurs du Congo et du monde</strong></p>

                <p class="justify">
                    Cette année 2022 qui va s’achever dans quelques jours est
                    semblable a une empreinte indélébile gravée dans toute l’histoire
                    de l’ASLA-OPH.
                </p>

                <p class="justify">
                    En effet, concernant la révélation du Dieu Créateur de l’univers et le
                    chemin que parcourt cet univers, depuis son existence, c’est au cours de
                    cette année 2022, à cette quatrième génération que Dieu le PERE, confirme
                    sa POSTURE. C’est-à-dire, la position réelle qu’il occupe dans l’univers
                    physique et spirituel dont il est le géniteur et le leader.
                </p>

                <p class="justify">
                    Effectivement, après que Papa Guy Émile LOUFOUA CETIKOUABO
                    ait accompli, le 20 mai 1992 de 20h 00 à 03 heures, la
                    première phase du plan de salut qui consistait à réunir
                    tout l’univers, c’est-à-dire, tout ce qui se trouve au ciel,
                    sur la terre et sous terre sous un seul chef (Eph.1.10),
                    il est rentré dans son règne, le premier janvier 2000, et i
                    l est établi depuis le 17 octobre 2004, pour régner
                    éternellement afin de restaurer et renouveler toutes
                    les choses (univers) ; c’est précisément, à partir de
                    cette année 2022, que Papa Guy Émile LOUFOUA CETIKOUABO,
                    place son peuple à un niveau très élevé de la spiritualité,
                    en décrétant qu’à partir de l’année 2022 commence la période
                    de la très haute spiritualité sans faille dans l’ASLA-OPH.
                    Autrement dit, le Grand Maitre de l’OPH fait entrer et place
                    sa deuxième nation au niveau ou elle commence
                </p>

                <p class="justify">
                    Or la Très Haute Spiritualité indique, ni plus ni moins,
                    la POSTURE de Dieu. C’est-à-dire, la position très élevée
                    qu’occupe Dieu le Père, Lui-même, sur tous les plans : la
                    Localisation (du haut des cieux), la Connaissance
                    (Omniscience), la Sainteté (sa nature), la Lumière
                    (sa reconnaissance), la Royauté manifestée par sa Puissance
                    (Tout Puissant) et son Omniprésence (il est tout et présent
                    en tous)
                </p>

                <p class="justify">
                    Les Écritures affirment clairement que Dieu est :
                    le Dieu Très Haut (Ps 97 :9), le Dieu des Cieux,
                    souverainement élevé au-dessus de tous les dieux.
                    En conséquence, tout ce qu’il a réuni auprès de Lui,
                    doivent également être placé à ces côtés, c’est dire à
                    un niveau très élevé là où il se trouve.
                </p>

                <p><strong>Peuple de la deuxième nation</strong></p>

                <p class="justify">
                    Dans son message du le Grand Maitre de l’OPH,
                    nous apprend « qu’il nous a tout donné, qu’il nous a
                    tout dit, et notre réel plaisir de tous les jours
                    serait le fait de l’avoir connu, Lui et son association ».
                    Par conséquent, tout ce qui nous reste à faire, c’est de le
                    Glorifier, de l’honorer et de Lui rendre un culte agréable à
                    chaque occasion.
                </p>

                <p class="justify">
                    Il convient de souligner qu’à cette période de
                    là très haute spiritualité sans faille, Papa Émile
                    LOUFOUA CETIKOUABO, l’Esprit de Vérité demeure en chacun
                    de nous, est avec chacun de nous () et participe avec
                    chacun de nous à tout ce que nous entreprenons
                    individuellement ou collectivement.
                </p>

                <p class="justify">
                    En outre, sachant que la seule chose qui nous reste à faire,
                    c’est de le rendre grâce en toutes circonstances et de le louer
                    à chaque occasion que nous nous réunissons, Sachant aussi que les
                    retrouvailles des enfants de la deuxième nation sont des saintes
                    convocations de Dieu, et que Dieu siège au milieu de la louange,
                </p>

                <p class="justify">
                    Il est donc impérieux de connaitre et d’intérioriser que,
                    lorsque le peuple de la deuxième nation de Dieu le Père se
                    réuni en assemblée spirituelle, Papa Guy Émile LOUFOUA
                    CETIKOUABO rentre triomphalement, avec tous nos concitoyens
                    célestes qui sont nos ancêtres les patriarches, Abraham,
                    Isaac, Jacob, et toute
                </p>

                <p><strong>Frères et soeurs en Cétikouabo, du Congo et du monde entier ;</strong></p>

                <p class="justify">
                    Nous devons donc observer scrupuleusement ces directives, au début,
                    au cours et la fin de la cérémonie. <strong>Parmi ces directives, nous citons
                        les acclamations qui sont un rituel de la bonne réception,
                        d’hospitalité et d’amour, ayant une très, très haute portée
                        spirituelle, et qui n’est pratiqué exclusivement que dans
                        l’ASLA-OPH où se trouve le Grand Dieu, le Dieu des dieux, le
                        Dieu très Haut</strong>.
                </p>

                <p class="justify">
                    Ainsi, à cette occasion frères et soeurs en Céti,
                    nous voulons rappeler en quelques mots, le sens premier
                    du terme « acclamation » et la portée spirituelle de
                    chacune des quatre acclamations que nous pratiquons dans
                    l’ASLA-OPH. Chacun de nous pourra les comprendre et bien
                    les pratiquer sans faille, lors de toutes les saintes
                    convocations de notre Père Céleste.
                </p>

                <p>
                    Le terme <strong>« acclamation »</strong> vient du verbe « acclamer » qui
                    a le sens d’applaudir, d’ovationner et de vanter. Autrement
                    dit acclamer dans la pratique consiste à « battre les mains,
                    faire des marques bruyants d’enthousiasme tels que les cris,
                    lever les mains, lancer des paroles pour exprimer la joie,
                    l’estime afin d’honorer ou vanter un personnage ».
                </p>

                <p class="justify">
                    Les acclamations interviennent souvent lors des moments
                    solennels : Exemples : Dans nos sociétés à l’occasion des
                    grandes manifestations, à l’arrivée comme au départ d’une
                    autorité, les gens se mettent debout et/ou applaudissent
                    pour lui rendre hommage. De même, les gens acclament après
                    un discours, une adresse qui a suscité des émotions dans
                    l’auditoire lors d’une assemblée de quelque nature qu’elle
                    soit.
                </p>

                <p class="justify">
                    Dans la bible, il est clairement démontré que l’accueil
                    réservé à Jésus, lors de son entrée triomphale à Jérusalem
                    est une illustration de cette pratique des acclamations ().
                </p>

                <p><strong>Frères et soeur en Cétikouabo,</strong></p>

                <p class="justify">
                    Dans l’ASLA-OPH, la pratique des acclamations fait partie du
                    Culte agréable, recommandé par Notre Père Céleste, le Roi de
                    l’univers, le Roi des Rois de la terre, le Grand Maitre de
                    l’OPH, Papa Guy Émile LOUFOUA CETIKOUABO. Cependant, elle
                    est distincte de celle qui se passe dans le monde, car dans
                    le monde on fait des acclamations pour honorer des Hommes
                    par contre dans l’ASLA-OPH, on fait des acclamations pour
                    honorer et vénérer Dieu. En effet, dans l’ASLA-OPH, c’est
                    au cours des rencontres spirituelles, aussi bien
                    individuelles que collectives avec Dieu, qu’il est
                    recommandé d’acclamer, la Seule et Unique Autorité qui
                    est Notre Père Céleste, le Roi, Sauveur et Protecteur,
                    Papa Guy Émile LOUFOUA CETIKOUABO. C’est Lui, le Divin
                    Père qui rentre en action pour superviser sa séance dans
                    son peuple.
                </p>

                <p class="justify">
                    En outre, les acclamations dans l’ASLA-OPH sont cadrées
                    et structurées en des déclarations qui expriment la nature,
                    les fonctions et l’État d’Esprit de Notre Père que nous
                    honorons et vantons les mérites. Ces acclamations sont
                    également accompagnées des exclamations, c’est-à-dire, les
                    gestes et des mouvements qu’il faut accomplir, des paroles
                    qu’il faut prononcer et de l’attitude qu’il faut afficher
                    lorsqu’on acclame Dieu.
                </p>

                <p class="justify">
                    Ces exclamations sont une réponse du peuple
                    (la deuxième nation) pour acquiescer, approuver,
                    vanter et vénérer Dieu, après avoir déclaré ce que
                    Dieu est sur sa nature, ses attributs et son État
                    d’Esprit. La deuxième nation doit faire des exclamations
                    par des cris, des mouvements qui prouvent son admiration,
                    son émotion, sa surprise, sa stupéfaction, son très grand
                    étonnement complet conformément à . Exo 32.10, Ps.40 :15-18
                </p>

                <p class="justify">
                    Cette attitude de joie, d’enthousiasme s’impose à chaque
                    frère, chaque soeur, chaque Louzolifié réuni en assemblée
                    ou en communion avec son Dieu. Papa Guy Émile LOUFOUA
                    CETIKOUABO doit être accueilli avec des acclamations
                    lorsqu’il entre dans chaque assemblée spirituelle pour
                    diriger Lui-même la séance. Et, durant tout le temps que
                    durera la séance, le peuple doit être conscient et rassuré
                    que qu’il est assisté et doit formuler les quatre (4)
                    acclamations suivantes :
                </p>

                <p class="justify">
                <ol>
                    <li>
                        <strong>
                            Première acclamation : Acclamons pour notre Créateur
                            Céleste, OPH Sébaoth, Yahvé le TOUT-PUISSANT,
                            le Dieu d’Israël, Dieu des Armées, le Célèbre des
                            célèbres, le Dieu des dieux, le Père Céleste,
                            le Redoutable, Dieu d’Abraham, d’Isaac et de Jacob,
                            le Dieu très Haut !
                        </strong>
                    </li>
                    <br>
                    <p>
                        Papa GELC nous indique les appellations sur ses
                        fonctions, qu’il faut absolument, actuellement
                        proclamer conformément à Exo 34 :14 ; Apo 2 : 4 ;
                    </p>

                    <li>
                        <strong>
                            Deuxième acclamation : Acclamons Le Dieu de Paix
                            et de gloire, de sa puissance, de sa miséricorde
                            et de son amour infini.
                        </strong>
                    </li>
                    <br>
                    <p>
                        <strong>La paix :</strong> Il n’aime pas les conflits, il ne veut pas être
                        dérangé. C’est
                        pourquoi, il a accordé la Paix à l’humanité toute entière. Les hommes doivent
                        s’accorder, cultiver la concorde pour faire la paix entre eux et vivre en paix,
                        <br>
                        <strong>La Gloire :</strong> Toute l’humanité doit le connaitre et le louer et
                        expérimenter sa puissance, sa miséricorde et son amour infini car
                        la gloire revient à Lui seul.
                    </p>

                    <li>
                        <strong>
                            Troisième acclamation : Le Dieu des Cieux, le
                            Dieu rédempteur, le Dieu vivifiant, l’Alpha et
                            l’Omega
                        </strong>

                        <p>
                            <strong>Dieu des Cieux :</strong> les cieux son la résidence, son
                            habitation, sa demeure. <br>
                            <strong>Dieu rédempteur :</strong> le fait de racheter l’humanité toute
                            entière
                            (Racheter : faire oublier ou faire pardonner, racheter les fautes :
                            1 Pie 1 :18 ; Apo 5 :9 ; Gal 3 :13 ; 4 :5 ; 1Corint 6 :20. <br>
                            <strong>Dieu vivifiant :</strong> ce lui qui revigore et maintien en vie les
                            humains
                            - Alpha et Omega : il est le commencement et la fin de tout ce qui existe.
                        </p>
                    </li>

                    <li>
                        <strong>Quatrième acclamation : Acclamons Dieu le Père, Dieu le Fils et Dieu le
                            Saint Esprit.</strong>
                        <br>
                        <p>
                            C’est le Dieu trinitaire qui est relatif à la sainte trinité accomplie
                            sur terre. Ils sont tous les trois révélés en : Exo 3 :13-15 ; Jn 4 :25-26 ;
                            Jn 16 : 10-15. Alors, Tout est accompli, Tout est donné, Tout est à notre
                            portée. Toute la promesse s’est accomplie le 31 décembre 1999 à 00h00.
                        </p>

                        <p>
                            Faisant attention à toutes ces promesses, approprions-nous de
                            toutes ces promesses et craignons Dieu. Ne nous mettons plus
                            en contradiction avec Dieu. Il doit être la priorité des
                            priorités de notre vie
                        </p>
                    </li>
                </ol>
                </p>

                <p>
                    <strong>Frères et soeurs, les fidèles adorateurs du Christ tant attendu,</strong>
                </p>

                <p class="justify">
                    Nous devons entrer dans l’année 2023, avec une
                    conscience pure et une connaissance parfaite de la
                    manière pratique dont il faut rendre un culte
                    agréable à notre Père, notre Dieu Papa Guy Émile
                    LOUFOUA CETIKOUABO.
                    <br>
                    Les acclamations font une partie des rites
                    spirituels de haute portée spirituelles dans la
                    glorification de Dieu l’ASLA-OPH car sur le plan
                    administratif et social, l’ASLA-OPH continue,
                    lentement mais surement, à poser les oeuvres de
                    bienfaisances au Congo et dans le monde. La carte
                    de visite de l’ASLA-OPH nous édifie sur les
                    grandes réalisations opérées ces dernières années.
                </p>

                <p class="justify">
                    C’est ainsi que , en reconduisant le signe de l’année 2022,
                    selon lequel qu’ à partir de l’année 2022 et toute la période
                    avenir jusqu’<strong>à ce que l’OPH jugera utile de changer,
                        cette période est placée sous le signe de la très haute
                        spiritualité sans faille, l’amour sincère du prochain,
                        en acceptant et appliquant, l’enseignement de l’OPH,
                        au sein de l’ASLA-OPH et mener la vie qui honore Dieu
                        afin d’être aussi honoré par Dieu »</strong>, les objectifs retenus
                    depuis l’année 2022, sont reportés en 2023, afin de les
                    rendre opérationnel par toutes les structures de notre
                    chère association.
                </p>

                <p class="justify">
                    En guise de rappel, il s’agit de :
                <ol>
                    <li>
                        Intérioriser, pratiquer, expérimenter et propager à travers le monde les Vérités
                        Divines de l’Heure (VDH) ;
                    </li>

                    <li>
                        Rendre attrayantes et réussir toutes les manifestations spirituelles (fêtes,
                        réunions spirituelles et autres) de Dieu dans l’ASLA-OPH.
                    </li>

                    <li>
                        Programmer, organiser, coordonner et réaliser dans l’unité les actions
                        d’aménagement de la ville sainte de Kibossi, les actions de la reconstitution du
                        Jardin d’Eden, les actions de mise en valeur des sites d’accueil du mont des
                        oliviers (à Loudima), du Mont BOUYENGUI (à Brazzaville) et la cité
                        Cétikouabienne de NGO.
                    </li>

                    <li>
                        Programmer, organiser, coordonner et réaliser dans l’unité les activités
                        agricoles créatrices des richesses, des activités génératrices des revenus (AGR)
                        par structure et par section pour pratiquer effacement l’entraide et la
                        bienfaisance.
                    </li>
                </ol>
                </p>

                <p class="justify">
                    Je vous souhaite bonne et heureuse année 2023 et Que Papa Guy Émile LOUFOUA
                    CETIKOUABO nous bénisse tous.
                </p>

                <p class="justify">
                    Que la fête soit belle et pleine d’allégresse. <br>
                    Je vous remercie
                </p>

                <p>Amen, Amen, Amen.</p>

                <p style="text-align: right">
                    <strong>Jean Pierre BIKINDOU BISSOMBOLO. -</strong>
                </p>
            </div>
        </section>
        <Footer />
    </div>
</template>

<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
  },
};
</script>

<style scoped>
.text-justify {
  text-align: justify;
}
</style>