<template>
  <!-- navbar-->
  <header class="header">
    <!-- Primary Navbar-->
    <nav
      class="navbar navbar-expand-lg navbar-light p-0 border-bottom border-gray bg-color"
      style=""
    >
      <div class="container d-flex align-items-center justify-content-between">
        <router-link class="navbar-brand" :to="{ name: 'Index' }">
          <img
            src="../assets/img/LogoAsla.png"
            alt=""
            width="150"
            class="img-thumbnail"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="list-inline mb-0 d-none d-lg-block">
          <li class="list-inline-item me-3">
            <div class="d-flex">
                <i class="fa-solid fa-kaaba"></i>
              <div class="ms-2">
                <h6 class="text-uppercase mb-0 text-sm text-white">
                  Association Louzolo Amour OPH
                </h6>
                <p class="small text-gray mb-0">
                  Les Vérités Divines de l'Heure en ligne
                </p>
              </div>
            </div>
          </li>
          <li class="list-inline-item">
            <div class="d-flex">
                <i class="fa-solid fa-globe"></i>
              <div class="ms-2">
                <h6 class="text-white text-uppercase mb-0 text-sm">
                  ASLA-OPH CONNECT
                </h6>
                <p class="small text-gray mb-0">Site officiel</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Secondary Navbar                -->
    <nav class="navbar navbar-expand-lg navbar-light py-0 shadow-sm bg-white">
      <div class="container">
        <div
          class="collapse navbar-collapse py-3 py-lg-0"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <router-link
                class="nav-link text-uppercase letter-spacing-0"
                :to="{ name: 'Index' }"
                >Accueil
              </router-link>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link text-uppercase letter-spacing-0 dropdown-toggle"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >GELC</a
              >
              <div
                class="dropdown-menu border-0 border-top border-2 border-primary shadow-sm mt-0"
                aria-labelledby="navbarDropdown"
              >
                <a class="dropdown-item" href="#!">Personnalité humaine</a
                ><a class="dropdown-item" href="#!">Personnalité divine</a>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link text-uppercase letter-spacing-0 dropdown-toggle"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >ASLA-OPH</a
              >
              <div
                class="dropdown-menu border-0 border-top border-2 border-primary shadow-sm mt-0"
                aria-labelledby="navbarDropdown"
              >
                <router-link :to="{ name: 'asla' }" class="dropdown-item">A propos</router-link>
                <router-link :to="{ name: 'organigramme' }" class="dropdown-item">Organigramme</router-link>
                <!-- <a class="dropdown-item" href="#!">Vue des structures</a> -->
                <router-link :to="{ name: 'sites-intern' }" class="dropdown-item">Sites internationaux</router-link>
                <a class="dropdown-item" href="#!">Sites touristiques</a>
                <a class="dropdown-item" href="#!">Nos réalisations</a>
              </div>
            </li>

            <li class="nav-item dropdown">
              <a
                class="nav-link text-uppercase letter-spacing-0 dropdown-toggle"
                id="navbarDropdown"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                >Bibliothèque</a
              >
              <div
                class="dropdown-menu border-0 border-top border-2 border-primary shadow-sm mt-0"
                aria-labelledby="navbarDropdown"
              >
                <router-link
                  :to="{ name: 'Notes-circulaires' }"
                  class="dropdown-item"
                  >Notes circulaires</router-link
                >
                <!-- <a class="dropdown-item" href="#!">Messages transcrits</a> -->
                <router-link :to="{ name: 'vdh' }" class="dropdown-item"
                  >VDH</router-link
                >
                <!-- <a class="dropdown-item" href="#!">Autres documents</a> -->
              </div>
            </li>
            <!-- <li class="nav-item">
              <a
                class="nav-link text-uppercase letter-spacing-0"
                href="#actu"
                >ACTUALITE</a
              >
            </li> -->

            <!-- <li class="nav-item">
              <a
                class="nav-link text-uppercase letter-spacing-0"
                href="#"
                >Glossaire</a
              >
            </li> -->

            <li class="nav-item">
              <router-link
                :to="{ name: 'Contact' }"
                class="nav-link text-uppercase letter-spacing-0"
                >Contacts
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <!-- <li class="nav-item">
              <a
                class="nav-link px-4 d-inline-block"
                id="searchToggler"
                href="#"
                ><i class="fas fa-search text-muted"></i
                ><i class="fas fa-times text-muted d-none"></i
              ></a>
            </li> -->
            <li class="nav-item">
              <router-link :to="{ name: 'articles-blog' }"
                class="nav-link text-uppercase letter-spacing-0 border-start px-4 bg-primary text-white d-inline-block"
                >Articles de blog</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- <div class="p-3 shadow-sm index-forward border-top" id="search">
      <div class="container">
        <form action="#">
          <div class="input-group">
            <input
              class="form-control"
              type="search"
              placeholder="Effectuez une recherche ..."
            />
            <button class="btn btn-primary" type="button">
              <i class="fas fa-search me-2"></i>Rechercher
            </button>
          </div>
        </form>
      </div> 
    </div>-->
  </header>
</template>

 <style scoped>
.bg-color {
  background-color: #07609a;
}
</style>