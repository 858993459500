<template>
    <div class="jumbotron">
        <div align="center">
            <h1 class="display-4">
                <img src="../assets/img/blue-screen.png" width="150" alt="">
            </h1>
        </div>
        <p class="lead text-center">
            Aucune donnée trouvée
        </p>
        <hr class="my-4">
        <p class="text-center text-warning">
            Nous n'avons pas pu trouver une donnée par rapport à votre sélection ou recherche...
        </p>
    </div>
</template>

<style scoped>
    .jumbotron{
    background-color: rgb(71, 72, 72);
    color: #fff
}
</style>