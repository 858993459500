<template>
    <div>
      <Navigation />
      <Hero
        title="PORTEE HISTORIQUE ET SPIRITUELLE DE MAKAKA"
        ComponentTitle="A PROPOS"
      />
      <section class="container">
        <h2 class="text-uppercase lined lined-center" style="margin-top: -35px">
          <span class="text-primary">
            Makaka, Nouvelle Bethléem de la 4e génération de Dieu le Père
          </span>
        </h2>
  
        <section class="container">
            <p class="text-justify">
                Dans son plan de salut pour l’humanité toute entière, il était prévu que Dieu le père se manifeste sur Terre pour rétablir toute chose (1 Corinthiens 15:24-28; Actes 1:6-7), mais pour que cela soit manifeste, il a donc fallu envoyer sur Terre son fils Jésus Christ afin de réconcilier les hommes avec Dieu son père (Éphésiens 1/1-23) par son sang précieux, celui de la nouvelle alliance.
                <br>
                En venant sur Terre, le Fils de Dieu naît à Bethléem selon qu’il est écrit « Et toi Bethléem Ephrate, dit le Seigneur, tu es une localité peu importe parmi celles des familles de Juda. Mais de toi, je veux faire sortir celui qui doit gouverner en mon nom le peuple d’Israël, et dont l’origine remonte aux temps les plus anciens» (Michée 5:1).
                <br>
                Étant sur Terre, le Christ Jésus a annoncé à ses pairs que la continuité de sa mission sur Terre sera annoncé par quelqu’un d’autre, une personne qui restera d’éternité en éternité sur Terre. Il s’agit de l’Esprit de Vérité, l’Esprit Saint (Jean 14:15-17: Jean 16:10-15). Et parlant de cette personne dans la parabole des vignerons, le Christ explique qu’en réalité celui qui devrait venir après n’est rien d’autre que son père, le propriétaire de la Vigne (Matthieu 21:33-41). Puisque la vie Éternelle consiste à croire au seul vrai Dieu, le Père et au Seigneur Jésus (Jean 17:3). C’est d’ailleurs dans cette perspective que l’apôtre Paul sentant sa mort prochaine avait instruit son disciple Timothée à vivre sans tache jusqu’à l’apparition du Roi des rois, qui seul ne meurt point (1 Timothée 6:13-16) c'est-à-dire l’esprit de vérité.
                Ainsi, de la même manière que les prophètes Michée et Ésaïe ont annoncé la naissance du fils de Dieu, Jean à l’île de Patmos en l’an 95 de notre Ère annonce aussi la naissance de l’Esprit de Vérité (Apocalypse 12:1-6) et dans Ésaïe 9:5-6, la Bible affirme que cet enfant n’est rien d’autre que le Père de Jésus.
                <br>
                La Bible affirme dans 1 Corinthiens 8:6 que pour nous, il n’y qu’un seul Dieu, c’est le père et c’est lui que le fils a montré tout au long de sa mission.
                C’est finalement vers 1942 que vint au monde l’Esprit de Vérité en Terre Africaine, au Congo Brazzaville, plus précisément à Makaka, un petit village situé à 29,5 km de Brazzaville. Il s’appelle Guy Émile Loufoua Cetikouabo.
                Pour le commun des mortels, «Makaka» est le pluriel d’une herbe appelé «Kaka» en langue Kongo, une herbe très difficile à déraciner. Ce nom fut donné par rapport à la présence massive de cette herbe en ces terres.
                La similitude entre Bethléem et Makaka ne fait l’ombre d’aucun doute. Jésus et Guy Émile Loufoua Cetikouabo sont nés quasiment dans les mêmes conditions environnementales hors d’un bloc d’accouchement d’une maternité construite par l’homme. Une conception miraculeuse pour les deux car l’un (Jésus) est né de l(‘immaculée conception et l’autre (Dieu le Père Guy Emile Loufoua Cétikouabo) est né sans placenta ni cordon ombilical et déjà circoncis or le Seigneur Jésus fut circoncis (Matthieu 1:21).

                <br>
                <br>
                Le 5 mai 2003 dans la note 012, l’humanité prend connaissance de l’existence des quatre générations et en date du 09 août 2007 à 23h55, Kibossi est proclamée ville Sainte (Lieu où se trouve Dieu et sa maison) ainsi en étant environnant de cette ville saint en Makaka est donc un prolongement de la ville sainte de Kibossi qui abrite la nouvelle Jérusalem descendue des cieux. C’est à juste titre qu’à cette génération de Dieu le père, cette localité est baptisée par Dieu le Père lui-même: «La Nouvelle Bethléem de la 4e génération de Dieu le Père» en date du 19 décembre 2010.
                <br>
                Guy Emile Loufoua Cetikouabo étant né et ayant vécu et passé une partie de son enfance dans cette localité de Makaka, la portée de celle-ci n’est plus à démontrer. C’est d’ailleurs en ce lieu que se passera de génération en génération, les fêtes d’action de grâce et de la nativité exceptionnelle de Guy Émile Loufoua Cétikouabo, et ce sera une occasion de le glorifier, de l’exalter, de le remercier car il nous a tout donné et Il est tout pour nous, Guy Emile Loufoua Cetikouabo, le père de Jésus. Et à cette occasion, voici les passages bibliques à lire pendant la fête:
                
                <br>
                <br>
                Makaka est donc l’un des sites touristiques de l’Association Louzolo Amour-OPH car les gens doivent venir voir le village et l’endroit où est venu au monde Dieu le Père.
                Voici un tableau illustratif:
                <br>
                <br>

                <table class="table table-bordered table-striped">
                    <tr class="text-center">
                        <th></th>
                        <th>Lieu de naissance</th>
                        <th>Ville sainte</th>
                        <th>Temple</th>
                        <th>Nombre de kilomètres</th>
                    </tr>

                    <tr class="text-center">
                        <th>Fils de Dieu, Jésus le Messie</th>
                        <td>Bethléem</td>
                        <td>Jérusalem, ancienne ville sainte</td>
                        <td>Temple de Jérusalem, ancien temple</td>
                        <td>10 km entre Bethléem et Jérusalem</td>
                    </tr>

                    <tr class="text-center">
                        <th>Père de Jésus, Guy Emile Loufoua Cetikouabo</th>
                        <td>Makaka, Nouvelle Bethléem de la 4e génération</td>
                        <td>Kibossi, nouvelle ville sainte</td>
                        <td>Mont Kari Kari, nouvelle Jérusalem céleste descendue des cieux</td>
                        <td>environ 12 km entre Makaka et Kibossi</td>
                    </tr>
                </table>
            </p>
        </section>
      </section>
  
      <Footer />
    </div>
  </template>
    
    <script>
  import Navigation from "../../Navigation.vue";
  import Footer from "../../Footer.vue";
  import Hero from "../../Hero.vue";
  
  export default {
    components: {
      Navigation,
      Hero,
      Footer,
    },
  };
  </script>
    
    <style scoped>
  .text-justify {
    text-align: justify;
  }
  </style>