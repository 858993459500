<template>
    <div>
        <!-- Contact Section-->
    <section class="contact py-5 bg-primary text-white">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-4">
            <div class="d-flex align-items-center">
              <div class="contact-icon"><i class="fas fa-phone"></i></div>
              <div class="ms-3">       
                <h3 class="h5 mb-0">+242 06 828 9050</h3>
                <p class="small mb-0">Avez-vous des questions ?</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex align-items-center">
              <div class="contact-icon"><i class="far fa-envelope"></i></div>
              <div class="ms-3">       
                <h3 class="h5 mb-0">info@asla-oph.net</h3>
                <p class="small mb-0">Notre ligne de messagerie</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex align-items-center">
              <div class="contact-icon"><i class="fas fa-map-marker-alt"></i></div>
              <div class="ms-3">       
                <h3 class="h5 mb-0">2, rue Delamart, Moukondo (Brazzaville)</h3>
                <p class="small mb-0">Permanence</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="footer pt-5">
      <div class="container pt-5">
        <div class="row gy-4">
          <div class="col-lg-3">
            <h2 class="h5 lined text-white mb-4">Qui sommes-nous</h2>
            <p><img src="../assets/img/aOc.jpg" width="250" alt=""></p>
            <!-- <p class="text-muted text-sm">
                
            </p> -->
            <!-- <p class="text-muted text-sm">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p> -->
            <ul class="list-inline mb-0">
              <li class="list-inline-item"><a class="social-link" href="#!"><i class="fab fa-facebook-f"></i></a></li>
              <li class="list-inline-item"><a class="social-link" href="#!"><i class="fab fa-twitter"></i></a></li>
              <li class="list-inline-item"><a class="social-link" href="#!"><i class="fab fa-google-plus"></i></a></li>
              <li class="list-inline-item"><a class="social-link" href="#!"><i class="fab fa-instagram"></i></a></li>
            </ul>
          </div>
          <div class="col-lg-5">
            <h2 class="h5 text-white lined mb-4">Liens utils</h2>
            <div class="d-flex">
              <ul class="list-unstyled d-inline-block me-4 mb-0">
                <li class="mb-2"><router-link class="footer-link" :to="{name: 'Index'}">Accueil</router-link></li>
                <li class="mb-2"><router-link class="footer-link" :to="{name: 'asla'}">A propos</router-link></li>
                <li class="mb-2"><a class="footer-link" href="#!">Humanité</a></li>
                <li class="mb-2"><a class="footer-link" href="#!">Divinité</a></li>
                <li class="mb-2"><router-link :to="{name: 'articles-blog'}" class="footer-link">Articles</router-link></li>
              </ul>
              <ul class="list-unstyled d-inline-block mb-0">
                <li class="mb-2"><router-link class="footer-link" :to="{name: 'sites-intern'}">Sites </router-link></li>
                <li class="mb-2"><a class="footer-link" href="#">Tourisme</a></li>
                <li class="mb-2"><router-link class="footer-link" :to="{name: 'Notes-circulaires'}">Notes C.</router-link></li>
                <li class="mb-2"><router-link class="footer-link" :to="{name: 'vdh'}">VDH</router-link></li>
                <li class="mb-2"><router-link class="footer-link" :to="{name: 'Contact'}">Contacts</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <h2 class="h5 text-white lined mb-4">Newsletter</h2>
            <p class="text-muted text-sm">Soyez notifiés de nos récentes publications d'articles</p>
            <div class="input-group mb-3">
              <input class="form-control text-muted bg-none border-primary" type="text" placeholder="Adresse mail" aria-label="Recipient's username" aria-describedby="button-addon2">
              <button class="btn btn-primary" id="button-addon2" type="button"><i class="fas fa-paper-plane"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div class="copyrights">       
        <div class="container py-4">
            <p class="mb-0 text-center text-white text-sm"> 
                2023, <strong>ASLA-OPH</strong> Tous droits réservés <br>
                <a href="#" class="text-white">&copy; Développé par <strong>ASLA-OPH CONNECT</strong></a> <br>
                <a href="#" title="" class="sr-only">Marvy MOUANDA NGOMA</a>
            </p>
        </div>
      </div>
    </footer>
    </div>
</template>
