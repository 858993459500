<template>
  <div>
    <Navigation />
    <Hero :title="vdhTitle" ComponentTitle="VDH" />

    <div class="p-3 shadow-sm index-forward border-top" id="">
      <div class="container-fluid">
        <div class="card">
            <div class="card-header bg-primary-blue">
              Référence: {{ vdhRef }}
            </div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p class="height" v-html="vdhDesc"></p>
              </blockquote>
            </div>
          </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";
import axios from "axios";

import { useRoute } from "vue-router";
const route = useRoute;

export default {
  components: {
    Navigation,
    Hero,
    Footer,
  },

  data() {
    return {
      id: this.$route.query.vdh,
      slug: this.$route.query.titre,
      singleVDH: [],
      vdhTitle: null,
      vdhDesc: null,
      vdhRef: null,
    };
  },

  methods: {
    getSingleVDH() {
      axios
        .get("vdh/single", {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          this.vdhTitle = res.data[0].evenement;
          this.vdhDesc = res.data[0].description;
          this.vdhRef = res.data[0].reference;
        });
    },
  },

  mounted() {
    this.getSingleVDH();
  },
};
</script>

<style scoped>
.bg-primary-blue {
  background-color: #07609a;
  color: #fff;
}
</style>