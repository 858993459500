<template>
    <div>
        <Navigation />
        <Hero 
            title="A propos de l'association Louzolo Amour OPH" 
            ComponentTitle="A PROPOS" 
        />
        <section class="container">
            <h2 class="text-uppercase lined lined-center" style="margin-top: -35px">
                <span class="text-primary">de la philosophie et de son organisation</span>
            </h2>
            <p class="text-justify">
                - L’Association Louzolo « Amour » - OPH est une Association 
                de bienfaisance à caractère spirituel, régie par la loi de 1901. 
                Elle était anciennement enregistrée au Ministère de l’Intérieur 
                de la République du Congo sous le récépissé numéro 
                884/83/MINT/SGAT/DEC/SAG/ du 15 Avril 1983, et réactualisée 
                sous le récépissé numéro <strong>004/015/MID/DGAT/DER/SAG du12/03/15</strong> ce, 
                conformément aux textes fondamentaux révisés en février 2001
            </p>

            <p class="text-justify">
                - Elle a est créée par Papa Guy Émile LOUFOUA CETIKOUABO, 
                le Président Fondateur, Grand Maitre de l’OPH, l’Esprit de Vérité. 
                Il est en retraite spirituelle à Kibossi depuis 1988 selon la volonté 
                divine.
            </p>

            <p class="text-justify">
                - Le Président Fondateur est représenté par son Délégué Permanent 
                Jean Pierre BIKINDOU BISSOMBOLO, qui a reçu une délégation permanente, 
                chargé d’agir en son nom en tout lieu et en toute circonstance. 
            </p>

            <p class="text-justify">
                - Le siège social de l’Association Louzolo “Amour"- OPH se trouve à 
                Kibossi Gare- C.F.C.O, localité située à trente-huit kilomètres (38km) 
                de Brazzaville dans le Département du Pool, République du Congo. 
                Elle dispose d’une permanence au N° 2 de la rue Delamart, débaptisée 
                Charon d’Ur en Chaldée, au Quartier Moukondo, arrondissement 
                IV Moungali Brazzaville, République du Congo. 
            </p>

            <p class="text-justify">
                - L’Association Louzolo « Amour » - OPH est présente dans plusieurs pays parmi lesquels : le Gabon, l’Angola, l’Afrique du Sud, le Ghana, la Côte d’Ivoire, le Benin, la France, l’Angleterre, l’Allemagne, l’Italie, ,, les USA, le Canada… 
            </p>

            <p class="text-justify">
                - L’emblème de L’Association Louzolo « Amour » - OPH est le drapeau rectangulaire à quatre couleurs : Blanc-Bleu-Jaune-Rouge bordeaux. Il est frappé en son milieu et sur fond rouge bordeaux de deux carrés jaunes citron superposés, croisés liserés blanc et entourés de huit (8) rayons lumineux jaunes. 
            </p>

            <p class="text-justify">
                - Les deux carrés croisés superposés forment une étoile à huit (8) branches. Dans le fond rouge de cette étoile est placée une boule blanche à rayures bleues, symbolisant le globe terrestre, dans lequel sont représentées deux mains qui se saluent, insérées à leur tour dans un cœur de couleur rouge bordeaux. 
            </p>

            <p class="text-justify">
                <ul>
                    <li>Le blanc symbolise la Paix de Dieu ;</li>
                    <li>Le Bleu est le symbole de la pureté du Coeur ; </li>
                    <li>Le jaune est le symbole du feu du Saint Esprit ; </li>
                    <li>Le rouge Bordeaux est le symbole du Sang versé par Jésus Christ. </li>
                </ul>

                <strong>
                    - L’Étoile représente l’explosion de la lumière qui illumine tous les enfants de Dieu purifiés ayant reçus le baptême du Saint Esprit et le Baptême du Feu. 
                </strong>
            </p>

            <p class="text-justify">
                Les deux mains qui se saluent symbolisent l’amour fraternel. 
                L’hymne de l’Association Louzolo « Amour » - OPH est
                <strong>DEBOUT LE FONDATEUR T’APPELLE</strong> <br>

                L’Association Louzolo « Amour » - OPH a pour devise : 
                <strong>AMOUR SINCERE – UNITÉ - PAIX</strong>
            </p>

            <p class="text-justify">
                - L’Association Louzolo « Amour » - OPH est apolitique. Elle n’est ni une religion, ni une secte. Elle renferme ou accueille en son sein des croyants de tout horizon sans distinction de race, de sexe, de nationalité et de conditions sociales. 
            </p>

            <p class="text-justify">
                - Elle comporte aujourd’hui plus de 40.000 adhérents unis par les mêmes idéaux humanistes et spirituels basés sur les Saintes Ecritures. 
            </p>

            <p class="text-justify">
                <strong>L’adhésion est libre et volontaire. </strong> <br>
                - L’Association Louzolo « Amour » OPH accueille en son sein tous ceux qui aiment la vie communautaire et pratiquer l’amour sincère du Prochain au sens vrai du terme. Elle regroupe tous ceux qui ont soif du salut de Dieu, le salut éternel, quel que soient leurs croyances, d’où le qualificatif de « mère poule ». A la fin des temps, elle regroupera en son sein, tous les croyants qui prient le vrai Dieu. (Message audio du Grand Maitre de l’OPH du 27 aout 2007) 
            </p>

            <p class="text-justify">
                - Sa vocation est de rassembler en son sein tous ceux qui aspirent à la Paix des coeurs et à la communion d’esprit fondé sur les principes spirituels ayant pour base l’Amour sincère du Prochain, enseigné par le Grand Maitre de L’OPH. 
            </p>

            <p class="text-justify">
                - L’Association Louzolo « Amour » OPH est l’habit de l’OPH, c'est-à-dire le moyen organique, humain, l’expression de Dieu caché. Elle est le centre de diffusion des enseignements de l’OPH qui n’est autre que YAHVE L’ETERNEL, le Dieu Véritable, Lui-même Créateur de l’univers et de l’humanité. 
            </p>

            <p class="text-justify">
                - La hiérarchie dans l’Association Louzolo « Amour » OPH est définie comme suit : 
                <ul>
                    <li>Adhérents de l’OPH</li>
                    <li>Membres de l’OPH </li>
                    <li>Membres Confirmés de l’OPH</li>
                </ul>
            </p>

            <p class="text-justify">
                - L’Association Louzolo « Amour » OPH est organisée en plusieurs structures internationales, intermédiaires, centrales et de base qui jouent pleinement leurs rôles, chacune en ce qui la concerne. 
            </p>

            <p class="text-justify">
                - Le Président Fondateur de l’ASLA-OPH, Papa Guy Émile LOUFOUA CETIKOUABO est aussi le Président de toutes les structures en charge de la gestion de l’Association Louzolo « Amour » - OPH. 
            </p>

            <p class="text-justify">
                - Le pouvoir de nomination et de démission lui incombe exclusivement, et ce, conformément à l’article 12 des statuts de l’Association. 
            </p>

            <h2 class="text-uppercase lined lined-center">
                <span class="text-primary">Des réalisations sur le plan social </span>
            </h2>

            <p class="text-justify">
                Plusieurs structures socio-sanitaires ont été déjà réalisées, à savoir : 
                <ul>
                    <li>En République du Congo </li>
                    <p class="text-justify">
                        - Le Centre Médico-social de Kari-Kari à Kibossi-Gare (CMSK) qui, ouvert depuis décembre 1995 dans le département du Pool, est en fonctionnement continu jusqu’à ce jour. Les prix des soins dans ce centre varient entre 0 et 75 francs CFA maxi ; 
                    </p>
                    <p class="justify">
                        - Le Centre Médico-social de Loudima-Gare (CMSL) est en fonction depuis octobre 2003 dans le département de la Bouenza ; 
                    </p>
                    <p class="text-justify">
                        - Le Centre Médico-social de Pointe –Noire (CMSPN) fonctionne depuis 2006 ; 
                    </p>

                    <p class="text-justify">
                        - Le Centre Médico-social de NGO est opérationnel dans le département des Plateaux depuis 2011; 
                    </p>

                    <p class="text-justify">
                        - Le Centre Médico-social de Makaka (CMSM), situé dans la sous-préfecture de Goma Tsé-tsé, a été inauguré officiellement le 06 avril 2014; 
                    </p>

                    <p class="text-justify">
                        - Le Centre Médico-social de Boko dans le Département du Pool est en cours de construction ; 
                    </p>

                    <p class="text-justify">
                        - Le Centre médico- social intégré de l’ASLA-OPH à l’ile de Patmos à Pointe- Noire qui venait d’être inauguré le 2 juin 2022 ; 
                    </p>

                    <li>Sur le plan international</li>

                    <p class="text-justify">
                        - Le Centre Médico-social de Kplogodomey au Benin fonctionnement depuis mai 2013. 
                    </p>
                    <p class="text-justify">
                        - Les Centres Médico-sociaux du Gabon et de l’Angola sont en cours construction. 
                    </p>
                </ul>
            </p>

            <p>
                <strong>
                    Bien d’autres projets du même genre connaitront bientôt leur début de réalisation. 
                </strong>
            </p>

            <h2 class="text-uppercase lined lined-center">
                <span class="text-primary">De l’Éducation et de la Formation </span>
            </h2>

            <p>
                Dans l’ASLA-OPH on trouve deux (2) catégories d’éducation et de formation
            </p>

            <ul>
                <li>
                    La première catégorie est l’éducation de base et la formation spirituelle. 
                </li>

                <p class="text-justify">Il a quatre (4) programmes qui se juxtaposent dans cette catégorie, à savoir : </p>
                
                <p class="text-justify">
                    - Un programme pour les deux (2) niveaux d’éducation qui sont, l’Ecole des Cellules de Bases (ECB) dans les sous sections et les assemblées de Dimanche organisées par les bureaux des sections ; 
                </p>

                <p class="text-justify">
                    - Un second programme est consacré pour les Grandes Assemblées Spirituelles (GAS)lors des fêtes programmées au niveau international. 
                </p>

                <p class="text-justify">
                    Ces programmes d’éducation sont complétés par deux (2) autres programmes de formation spirituelle, dont : 
                </p>

                <p class="text-justify">
                    - Le programme de formation des Moniteurs de l’Ecole de Dimanche des Adultes en abrégé MEDA. Ce dernier est couplé avec le programme de formation des Prédicateurs ; 
                </p>

                <p class="text-justify">
                    - Le programme d’Education et de Formation de la Jeunesse (PEFJ) qui englobe les domaines de la spiritualité, du civisme et des métiers. 
                </p>

                <li>
                    La seconde catégorie est l’éducation et la formation scientifique 
                </li>

                <p class="text-justify">
                    - L’École de Mont Garizim « MBOLO Léandre Cyriaque » est située à NZAZA-NOA, à Kibossi-Gare en République du Congo. Elle assure le programme d’encadrement, d’éducation et de formation scientifique des enfants dans le premier et le deuxième cycle ; 
                </p>

                <p class="text-justify">
                    - Dans le domaine technique, en partenariat avec l’Etat congolais, le lycée technique agricole a débuté cette année 2022 après avoir été doté en table bancs. 
                </p>

                <p class="text-justify">
                    - Un Centre de formation en Coupe, et Couture et dans le domaine technique et des arts, vient d’être inaugurée et remis à la disposition de l’Etat à Makaka, le 1 septembre 2022 : 
                </p>

                <p class="text-justify">
                    - Dans la même localité de Makaka, une école du cycle primaire dénommé Joseph KIFOUETI NKOUKA a été également Inauguré et remis à l’Etat le même jour du 1 septembre 2022. 
                </p>

                <p class="text-justify">
                    - S’agissant de L’école des métiers à Kibossi, les travaux de réhabilitation des locaux sont en cours ; 
                </p>

                <p class="text-justify">
                    - D’autres structures de formation et de qualification professionnelle sont en cours de réalisation dans nombreux domaines. Celles-ci sont financées soit par fonds propres, soit en partenariat avec les Pouvoirs Publics ou avec les Partenaires techniques et Financiers (PTF). 
                </p>
            </ul>

            <h2 class="text-uppercase lined lined-center">
                <span class="text-primary">De la communication et des médias </span>
            </h2>

            <p><strong>L’Association Louzolo « AMOUR » - OPH dispose de : </strong></p>

            <p class="text-justify">
                - Deux chaines de Radios déjà fonctionnelles à Brazzaville, et à Pointe Noire depuis le 23 Aout 2011. 
            </p>

            <p class="text-justify">
                - Deux chaines de télévision. Une a Pointe Noire dénommées RTA – OPH qui fonctionne depuis 2011, et une autre à Brazzaville, dénommée RTA-OPH Monde qui est en phase d’expérimentation en diffusion ses émissions sur le web ; 
            </p>

            <h2 class="text-uppercase lined lined-center">
                <span class="text-primary">De la politique socio-économique </span>
            </h2>

            <p class="text-justify">
                - Dans le but de l’Association Louzolo "Amour“- OPH, le Président Fondateur a énoncé la vision globale de l’ASLA-OPH. Cette vision consiste à produire des œuvres de bienfaisance partout dans le monde. Il souligne que pour produire des œuvres de bienfaisance, l’ASLA-OPH doit disposer des gros moyens et pour ce faire, il encourage la création des activités génératrices de revenu. 
                <br>
                Autrement, pour générer des revenus permettant de mettre en oeuvre cette vision, l’ASLA-OPH compte développer des partenariats stratégiques avec les Etats et les Partenaires Techniques et Financiers. 
            </p>

            <p class="text-justify">
                - Dans la même perspective, l’Association a entamé des activités productives dans le département des plateaux, sur le site Cétikouabien de NGO qui regorge plusieurs espèces d’arbres fruitiers, notamment : 400 avocatiers, 400 ananas ; etc. 
                <br>
                Un autre site de 5 hectares dans la localité de Ngabé, ou les travaux d’amèrement ont commencé au cours du mois de septembre 2022 et se poursuivent pour le planning des espèces améliorés d’arbres fruitiers. 
            </p>

            <p class="text-justify">
                - En définitive, un Plan d’Action de Développement (PAD) de L’ASLA –OPH est élaboré pour mettre en cohérence la vision et la stratégie du Président Fondateur. Il servira de repère aux futures actions de l’Association. 
            </p>

            <p class="text-justify">
                - Voilà distingués invités, mesdames et messieurs, chers frères et soeurs en Guy Emile LOUFOUA CETIKOUABO, l’essentiel de cette carte de visite. 
            </p>

            <strong>MERCI -</strong>
        </section>
        <Footer />
    </div>
</template>

<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";

export default {
    components: {
    Navigation,
    Hero,
    Footer,
  },
}
</script>

<style scoped>
    .text-justify{
        /* text-align: justify */
    }
</style>