import { createRouter, createWebHistory } from 'vue-router'

import NotFound404 from "../components/404Error"

import Index from "../components/pages/Index";
import Contact from "../components/pages/Contact";
// Bibliothèque
import NotesCirculaires from "../components/pages/bibliotheque/NotesCirculaires";
import SingleNote from "../components/pages/bibliotheque/SingleNote";

import VDH from "../components/pages/bibliotheque/VDH";
import SingleVDH from "../components/pages/bibliotheque/SingleVDH";
// ASLA OPH
import SitesInternationaux from "../components/pages/asla/SitesInternationaux";
import Organigramme from "../components/pages/asla/Organigramme";
// Blog
import Articles from "../components/pages/blog/Articles";
import SingleArticle from "../components/pages/blog/SingleArticle";

import Asla from "../components/pages/asla/About";
import Dp from "../components/pages/asla/MotDP";

import MontOliviers from "../components/pages/asla/MontDesOliviers"
import MontBouyengui from "../components/pages/asla/MontBouyengui"
import Makaka from "../components/pages/asla/Makaka"

const routes = [
    // 
    {path: '/404', component: NotFound404, meta:{title: "PAGE INTROUVABLE"}},
    {path: "/:catchAll(.*)", redirect: '404'},

    { path: '/', name: 'Index', component: Index, meta:{title: "ACCUEIL"} },
    { path: '/contact', name: 'Contact', component: Contact, meta:{title: "CONTACTS"} },
    // Bibliothèque
    { path: '/notes-circulaires', name: 'Notes-circulaires', component: NotesCirculaires, meta:{title: "NOTES CIRCULAIRES"} },
    { path: '/single-note-circulaire', name: 'single-note-circulaire', component: SingleNote, meta:{title: "NOTE CIRCULAIRE"} },

    { path: '/verites-divines-de-l-heure', name: 'vdh', component: VDH, meta:{title: "VERITES DIVINES DE L'HEURE"} },
    { path: '/verite-divine-de-l-heure', name: 'singlevdh', component: SingleVDH, meta:{title: "VDH"} },
    //  ASLA OPH
    { path: '/sites-intenationaux', name: 'sites-intern', component: SitesInternationaux, meta:{title: "SITES INTERNATIONAUX"} }, 
    { path: '/organigramme', name: 'organigramme', component: Organigramme, meta:{title: "ORGANIGRAMME"} }, 
    // Blog
    { path: '/articles', name: 'articles-blog', component: Articles, meta:{title: "ARTICLES BLOG"} }, 
    { 
        path: '/article', 
        name: 'single-article', 
        component: SingleArticle,
        meta:{title: "ARTICLE"}
    }, 
    { path: '/presentation-asla-oph', name: "asla", component: Asla, meta:{title: "A PROPOS"} },
    { path: '/mot-du-delegue-permanent', name: "mot-dp", component: Dp, meta:{title: "ENONCE DES OBJECTIFS"} },
    
    { path: '/le-mont-des-oliviers', name: "mont-oliviers", component: MontOliviers, meta:{title: "MONT DES OLIVIERS"} },
    { path: '/le-mont-bouyengui', name: "mont-bouyengui", component: MontBouyengui, meta:{title: "MONT DES OLIVIERS"} },
    { path: '/makaka-nouvelle-bethleem', name: "makaka", component: Makaka, meta:{title: "MAKAKA"} },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active"
})

router.beforeEach((to, from, next)=>{
    let documentTitle = `${to.meta.title} - ASSOCIATION LOUZOLO AMOUR OPH (ASLA-OPH)`
    if(to.query.titre){
        documentTitle += ` | ${to.query.titre.toUpperCase()}`
    }
    document.title = documentTitle
    next()
})

export default router
