<template>
    <section class="hero bg-cover bg-top bg-img">
        <div class="container z-index-20 mt-lg-5">
          <div class="row align-items-lg-center gy-4">
            <div class="col-lg-9 order-2 order-lg-1">
              <h1 class="text-uppercase text-justify text-white">
                {{ title }} <span class="text-primary">{{ blueTitle }}</span>
              </h1>
              <p class="lead text-white mb-0">
                {{ subTitle }}
              </p>
            </div>
            <div class="col-lg-3 order-1 order-lg-2 text-lg-end">
              <!-- Breadcrumb-->
              <nav class="d-inline-block" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'Index' }">
                      <i class="fa fa-home me-2"></i>Accueil</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ComponentTitle}}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
export default {
    props:['ComponentTitle', 'title', 'blueTitle', 'subTitle']
}
</script>

<style scoped>
.bg-img {
  background-image: url("../assets/img/banniere5.jpg");
}

</style>