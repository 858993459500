<template>
  <div>
    <Navigation />
    <Hero
      title="PORTEE HISTORIQUE ET SPIRITUELLE DU MONT BOUYENGUI"
      ComponentTitle="A PROPOS"
    />
    <section class="container">
      <h2 class="text-uppercase lined lined-center" style="margin-top: -35px">
        <span class="text-primary">
          Portée historique et spirituelle du mont des oliviers
        </span>
      </h2>

      <section class="container">
        <h4>Au temps messianique,</h4>
        <P class="text-justify">
          Village situé à 15 stades ( Environ 3km) de Jérusalem ; du côté
          <router-link :to="{ name: 'mont-oliviers' }"
            ><strong>du Mont des oliviers</strong></router-link
          >
          , précisent Matthieu 11: 1 et Luc 29:29 . Simon le lépreux y possédait
          une maison ( Matthieu 26:6, Marc 14:3).C'est près de ce village que le
          Seigneur Jésus avait maudit le figuier ( Marc 11:12).
          <br />
          Marthe , Marie et Lazare demeuraient dans ce village et Lazare y fut
          ressuscité ( Jean 11:1) et ce village était assez voisin du Mont des
          oliviers. Béthanie était un endroit d'arrêt privilégié des Galiléens
          avant d'arriver à Jérusalem, après avoir pris la route du midi à l'est
          du Jourdain évitant ainsi de traverser les terres des Samaritains et
          prenant ensuite les hautes routes par Jéricho. Quand ils étaient à
          Jérusalem, le Seigneur Jésus et ses disciples passaient habituellement
          la nuit à bethanie ( Mathieu 21:17). C'est pour autant dire que
          Béthanie était la porte même de Jérusalem. Dans la conversation du
          Seigneur Jésus avec la femme Samaritaine ( Jean 4:19-24), Le Seigneur
          Jésus apprenait à cette femme que ça ne sera plus ni sur la montagne
          où ils se trouvaient,ni à Jérusalem que les gens doivent adorer le
          Père mais, ça sera à l'aide de l'Esprit Saint ,l'Esprit de vérité
          comme le Fils de Dieu l'a montré.
          <br />

          Dans Esaïe 2:2-3, le Prophète affirme que dans l'avenir, le temple ou
          la maison du Seigneur sera sur une haute montagne et les peuples y
          afflueront pour adorer le Père . Ainsi, à la différence du temple de
          Jérusalem, cette nouvelle demeure de Dieu , Lui seul l'Éternel sera
          l'architecte il s'agit de ce pas : de la nouvelle Jérusalem descendue
          des cieux ( Apocalypse 21:1-3), la nouvelle demeure de Dieu sur terre
          et Lui même Dieu habitera avec les Hommes comme il écrit :"Et
          j`entendis du trône une forte voix qui disait: Voici le tabernacle de
          Dieu avec les hommes! Il habitera avec eux, et ils seront son peuple,
          et Dieu lui-même sera avec eux"( Apocalypse 21:3). Cette nouvelle
          Jérusalem est notre Mère ( Galates 4:26) car tous y sont nés ( Psaumes
          87:1-6).
        </P>

        <h4 class="">Au temps de Dieu le Père,</h4>
        <p class="text-justify">
          Étant donné que cette nouvelle Jérusalem était déjà descendue des
          cieux en date du 20 Mai 1992 de 20heures à 3heures du matin
          <router-link to="">
            <strong>
              au Mont Kari Kari( Montagne située à Kibossi Gare CFCO à 38 km de
              Brazzaville dans le département du Pool, district de Goma tsé tsé)
            </strong>
          </router-link>
          la nouvelle demeure de Dieu et que l'Esprit de Vérité, Dieu le Père
          pour qui cette épouse qu'est la nouvelle Jérusalem céleste est
          descendue, il n'est plus ambigu de voir un endroit que les frères et
          sœurs de L'Asla OPH porté le Nom de Mont Bethanie.
        </p>

        <h4>Pourquoi le Mont BOUYENGUI SITE DE BÉTHANIE ?</h4>

        <p class="text-justify">
          C'est tout simplement pour jouer le même rôle que l'ancien bethanie
          biblique; celui d'être la porte de la nouvelle Demeure de Dieu. Voilà
          pourquoi cette montagne porte la dénomination de "La porte du Ciel" ou
          bien la porte du
            <router-link to="">
                <strong>Mont Kari Kari qui est ce même ciel</strong>
            </router-link> <br/>
          . De ce fait, tout pèlerins désireux séjourner dans la ville Sainte de
          kibossi doit passer nécessairement par le Mont BOUYENGUI SITE DE
          BÉTHANIE La porte du Ciel . C'est finalement en Décembre 2007 que ce
          site fut accessible à tout le monde et le 31 décembre 2007 dans la
          note 077 de la même date , la soeur MPASSI Exaucée est nommée
          Directrice Générale Adjointe de toutes les activités productives au
          Mont Bethanie ( Mont BOUYENGUI) car dans la vision du Président
          Fondateur, ce site est un lieu d'activités productives et aussi un
          endroit d'hébergement des frères et sœurs venant des extrémités de la
          terre, désireux se rendre à kibossi pour leur méditation.
          <br>
          <br>
          Il sied de souligner que le MONT BETHANIE est un lieu de méditation et de 
          prière , au sujet de cette montagne le Grand Maître de l'OPH stipule que 
          tout en étant la porte du ciel , le Mont Bethanie est de ce pas le lieu où 
          Il était venu se reposer, lorsqu'Il (Dieu le Père )  avait fini de  
          parcourir le monde pour contempler sa création, avant de regagner sa 
          Demeure qui est le <router-link to=""><strong>Mont Kari-Kari</strong></router-link>. Voilà pourquoi cette montagne aussi 
          est sainte au point où, le Grand Maître de l'OPH Dieu le Père dit que 
          les hommes de la terre ont la possibilité de s'y rendre pour se 
          receuillir, méditer et prier l'Éternel Dieu.
           <br>
           Et en montant le Mont Bethanie avec foi , Dieu ajoute une année de plus 
           sur notre vie .voyez à quel point cette montagne est d'une importance 
           capitale pour la méditation.
        </p>
      </section>
    </section>

    <Footer />
  </div>
</template>
  
  <script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
  },
};
</script>
  
  <style scoped>
.text-justify {
  text-align: justify;
}
</style>