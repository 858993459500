<template>
    <div>
        <Navigation />
        <Hero
        title="Notes"
        blueTitle="circulaires"
        ComponentTitle="Notes Circulaires"
        subTitle="Retrouvez toutes les notes circulaires dans l'ASLA-OPH"
        />

        <div class="p-3 shadow-sm index-forward border-top" id="">
            <div class="container">
                <!-- <pdf :src="`https://admin.asla-oph.net/public/storage/noteCirculaires/${title}`"></pdf> -->
                <pdf src="https://pspdfkit.com/downloads/pspdfkit-web-demo.pdf" :page="1" />
            </div>
        </div>
    </div>
</template>
 
<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";
import NoData from "../../NoData.vue";
// import pdf from 'vue-pdf';
import pdf from 'pdfvuer';

import axios from "axios";

export default {
    components: {
    Navigation,
    Hero,
    Footer,
    NoData,
    pdf,
  },

  data(){
    return{
        fichier: this.$route.query.fichier,
        id: this.$route.query.id,

        title: null,
        singleNote: []
    }
  },

  mounted(){
    axios
      .get("single-note-circulaire", {
        params: {
          id: this.id,
        },
      })
      .then((res) => {
        this.singleNote = res.data;
        this.title = res.data[0].fichier;
        this.loader = false;
        console.log(res.data)
      });
  }
}
</script>