<template>
  <div>
    <Navigation />
    <Hero
      title="Articles"
      blueTitle="de blog"
      ComponentTitle="Articles"
      subTitle="Tous nos articles autour de la doctrine cétikouabienne"
    />

    <div class="p-3 shadow-sm index-forward border-top" id="">
      <div class="container">
        <!-- <form action="#">
                    
                </form> -->
        <div class="form-group">
          <label for="">
            <strong>
              Recherchez par mot clé parmi nos {{ counterArticle }} articles
            </strong>
          </label>
          <div class="input-group">
            <input
              v-model="searchModel"
              type="text"
              class="form-control"
              placeholder="Trouvez facilement un article ..."
            />

            <button class="btn btn-primary" type="button">
              <i class="fas fa-search me-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3 shadow-sm index-forward border-top" id="">
      <div class="container-fluid">
        <div class="row mt-4">
          <div class="col-md-9">
            <div class="row">
              <div align="center" class="" v-if="loader">
                <img src="../../../assets/spinner.gif" width="80" alt="" />
              </div>

              <div
                v-for="(article, index) in search"
                :key="index"
                v-if="!loader && articles.length > 0"
                class="col-lg-6 mb-3"
              >
                <div class="card card-perso">
                  <div class="card-header text-uppercase bg-primary-blue">
                    Catégorie: {{ article.category.libelle }}
                  </div>
                  <div class="card-body">
                    <p class="height">
                      {{ article.title }}
                    </p>
                    <hr />

                    <div class="bg-info-article">
                      <p class="p-1">
                        <span class="whriter_display">
                          <i class="fa-solid fa-pencil"></i> Par
                          {{
                            article.user.first_name + " " + article.user.name
                          }}
                        </span>
                        <i class="far fa-clock"></i>&nbsp;
                        <timeago
                          :datetime="article.date_time_pub"
                          auto-update
                        />
                      </p>
                    </div>
                  </div>

                  <div class="card-footer">
                    <router-link
                      :to="{
                        name: 'single-article',
                        query: {
                          article: article.id,
                          titre: article.slug.toLowerCase(),
                        },
                      }"
                      class="btn btn-secondary form-control"
                    >
                      <i class="fa-solid fa-eye"></i> En savoir plus
                    </router-link>
                  </div>
                </div>
                <br />
              </div>

              <no-data v-if="!loader && search.length <= 0" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <div class="card-header text-uppercase bg-primary-red">
                <strong>Filtres</strong>

                <span
                  class="float-right"
                  v-if="searchModel || CategoryModel || auteurModel"
                >
                  <a
                    href="#"
                    title="Annuler le filtre"
                    @click.prevent="cancelSearch()"
                    class="btn btn-warn"
                  >
                    <i class="fas fa-times"></i>
                  </a>
                </span>
              </div>

              <div class="card-body">
                <aside>
                  <div class="category_filter">Par Catégorie</div>

                  <div>
                    <div
                      v-for="(cat, index) in categoryOption"
                      :key="index"
                      class="form-check form-switch"
                    >
                      <input
                        class="form-check-input"
                        v-model="CategoryModel"
                        :value="cat.name"
                        type="radio"
                        role="switch"
                        :id="cat.id"
                      />
                      <label class="form-check-label" :for="cat.id">
                        {{ cat.name }}
                      </label>
                    </div>
                  </div>
                </aside>
                <!-- <hr />
                <aside>
                  <div class="category_filter">Par Ecrivain</div>
                  <div>
                    <div 
                        class="form-check form-switch"
                        v-for="(item,index) in ecrivainOption"
                        :key="index"
                        >
                      <input
                        class="form-check-input"
                        type="radio"
                        role="switch"
                        :id="item.id"
                        v-model="auteurModel"
                        :value="item.name"
                      />
                      <label
                        class="form-check-label"
                        :for="item.name"
                        >{{ item.name }}</label
                      >
                    </div>
                  </div>
                </aside> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>
  
  <script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";
import NoData from "../../NoData.vue";
import axios from "axios";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
    NoData,
  },

  data() {
    return {
      searchModel: "",
      CategoryModel: "",
      auteurModel: "",
      articles: [],
      loader: false,
      category: [],
      ecrivains: [],
      listEcrivain: [],

      categoryList: [],
      categoryOption: [],

      ecrivainList: [],
      ecrivainOption: [],
      counterArticle: null,
    };
  },

  computed: {
    search() {
      return this.articles.filter((item) => {
        return (
          (item.title.toLowerCase().includes(this.searchModel.toLowerCase()) ||
            item.content
              .toLowerCase()
              .includes(this.searchModel.toLowerCase())) &&
          (item.category.libelle
            .toLowerCase()
            .includes(this.CategoryModel.toLowerCase()) ||
            item.user.name
              .toLowerCase()
              .includes(this.auteurModel.toLowerCase()))
        );
      });
    },
  },

  methods: {
    async getArticle() {
    //   this.loader = true;
    //   await axios.get("blog/all-articles").then((res) => {
    //     this.articles = res.data;
    //     this.loader = false;

    //     for (let i = 0; i <= this.articles.length; i++) {
    //       if (!this.categoryList.includes(this.articles[i].category.libelle)) {
    //         this.categoryList.push(this.articles[i].category.libelle);
    //       }
    //     }
    //   });

    //   const timer = setInterval(() => {
    //     console.log("OK")
    //     // let arr = this.categoryList.sort();
    //     // for (let j = 0; j < arr.length; j++) {
    //     //   this.categoryOption.push({
    //     //     id: arr[j],
    //     //     name: arr[j],
    //     //   });
    //     // }
    //   }, 500);
    },

    async getCounter() {
      await axios.get("get-counter").then((res) => {
        this.counterArticle = res.data.Article;
      });
    },

    cancelSearch() {
      (this.searchModel = ""),
        (this.CategoryModel = ""),
        (this.auteurModel = "");
    },

    async saveView(id) {
      // await axios.post("create-view-article", {
      //     article_id: id,
      // });
    },
  },

  mounted() {
    // this.getArticle();
    this.getCounter();

    this.loader = true;
      axios.get("blog/all-articles").then((res) => {
        this.articles = res.data;
        this.loader = false;

        for (let i = 0; i <= this.articles.length; i++) {
          if (!this.categoryList.includes(this.articles[i].category.libelle)) {
            this.categoryList.push(this.articles[i].category.libelle);
          }
        }
      });

      setTimeout(() => {
        
        let arr = this.categoryList.sort();

        for (let j = 0; j < arr.length; j++) {
          this.categoryOption.push({
            id: arr[j],
            name: arr[j],
          });
        }
      });
  },
};
</script>
  
  <style scoped>
.bg-primary-blue {
  background-color: #07609a;
  color: #fff;
}

.card-perso {
  height: 370px;
}

.height {
  height: 135px;
  /* background-color: rgb(253, 252, 252); */
  padding: 5px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #8f4f4a;
}

.bg-info-article {
  background-color: rgb(224, 221, 221);
  border-radius: 5px;
}

.bg-primary-red {
  background-color: #8f4f4a;
  color: #ffffff;
  /* text-align: center; */
}

.btn-warn {
  /* background-color: red; */
  color: yellow;
}

.category_filter {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-weight: bold;
  color: #07609a;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .whriter_display {
    display: none;
  }
}
</style>