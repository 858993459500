<template>
    <div>
      <Navigation />
      <Hero 
          title="Organigramme"
          blueTitle="ASLA OPH"
          ComponentTitle="Organigramme"
          subTitle="Découvrez l'organigramme au sein de l'Association Louzolo Amour OPH" 
          />
  
        <div class="p-3 shadow-sm index-forward border-top" id="">
            <div class="container-fluid">
                <img src="../../../assets/img/organigramme.jpg" class="img-responsive" alt="">
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
  import Navigation from "../../Navigation.vue";
  import Footer from "../../Footer.vue";
  import Hero from "../../Hero.vue";
  
  export default {
    components: {
      Navigation,
      Hero,
      Footer,
    },
  };
  </script>

  <style scoped>
    .img-responsive{
        max-width: 100%
    }
  </style>