<template>
  <div>
    <Navigation />
    <Hero
      title="Verités divines"
      blueTitle="de l'heure"
      ComponentTitle="VDH"
      subTitle="Retrouvez l'ensemble des Vérités Divines de l'Heure révelées par Papa Guy Emile LOUFOUA CETIKOUABO"
    />

    <div class="p-3 shadow-sm index-forward border-top" id="">
      <div class="container">
        <!-- <form action="#">
              
          </form> -->
        <div class="form-group">
            <label for="">
                <strong>Plus de {{ counterVDH }} V.D.H enregistrées </strong>
            </label>
          <div class="input-group">
            <input
              v-model="searchModel"
              type="text"
              class="form-control"
              placeholder="Exemple: Dieu a accordé la paix ..."
              
            />
            <!-- @keyup="searchVDH(searchModel)" -->
            <button class="btn btn-primary" type="button">
              <i class="fas fa-search me-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div align="center" class="" v-if="loader">
      <img src="../../../assets/spinner.gif" width="80" alt="" />
    </div>

    <div class="container mt-5 mb-5">
      <div class="row">
        <div
          class="col-md-6"
          v-for="(vdh, index) in searchVDH"
          :key="index"
          v-if="!loader && searchVDH.length>0"
        >
          <div class="card">
            <div class="card-header bg-primary-blue">
              {{ vdh.reference }}
            </div>
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p class="height">{{ (vdh.evenement) }}</p>
                <footer class="">
                    <!--  -->
                  <router-link 
                    :to="{ name: 'singlevdh', query:{vdh: vdh.id, titre: vdh.slug.toLowerCase()} }" 
                    class="btn btn-secondary form-control"
                    >
                    <i class="fa-solid fa-eye"></i> En savoir plus
                  </router-link>
                </footer>
              </blockquote>
            </div>
          </div>
          <br>
        </div>

        <no-data v-if="!loader && searchVDH.length<=0"/>
      </div>

      <!-- <paginate v-if="!loader && searchVDH.length>0"
        v-model="page"
        :page-count="20"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="'Préc'"
        :next-text="'Suiv'"
        :container-class="'pagination'"
        :page-class="'page-item'"
        :onPageChange="searchVDH"
        >
      </paginate> -->
    </div>

    <Footer />
  </div>
</template>
  
  <script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";
import NoData from "../../NoData.vue";
import axios from "axios";

import Paginate from "vuejs-paginate-next";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
    NoData,
    Paginate
  },

  data() {
    return {
      searchModel: "",
      vdhTable: [],
      loader: false,
      counterVDH: null,
      page: 5,
    };
  },

  computed:{
    searchVDH() {
      this.loader = false
      return this.vdhTable.filter((vdh)=>{
        return vdh.evenement.toLowerCase().includes(this.searchModel.toLowerCase()) ||
                vdh.reference.toLowerCase().includes(this.searchModel.toLowerCase()) ||
                vdh.description.toLowerCase().includes(this.searchModel.toLowerCase())
      })
    },
  },

  methods: {
    getVDH() {
      axios.get("vdh")
      .then((res) => {
        this.vdhTable = res.data;
        this.loader = false
      })
    },

    async getCounter(){
        await axios.get('get-counter')
        .then(res=>{
            this.counterVDH = res.data.VDH
        })
    },

    clickCallback(pageNum) {
        console.log(pageNum)
    }
  },

  mounted() {
    this.loader = true
    this.getVDH();
    this.getCounter();
  },

  watch: {},
};
</script>
  
  <style scoped>
.bg-primary-blue {
  background-color: #07609a;
  color: #fff;
}

.card {
  height: 250px;
}

.height {
  height: 100px;
}
</style>
  
  