<template>
  <div>
    <Navigation />
    <Hero
      title="Notes"
      blueTitle="circulaires"
      ComponentTitle="Notes Circulaires"
      subTitle="Retrouvez toutes les notes circulaires dans l'ASLA-OPH"
    />

    <div class="p-3 shadow-sm index-forward border-top" id="">
      <div class="container">
        <form action="#">
            <div class="form-group">
                <label for="">
                    <strong>
                        Sélectionnez une année pour trouver une note parmi nos {{ counterNotes }} notes disponibles
                    </strong>
                </label>
                <div class="input-group">
                    <select v-model="yearModel" class="form-control" id="">
                        <option value="">-------</option>
                        <option :value="year" v-for="(year, index) in annee" :key="index">{{ year }}</option>
                    </select>
                    <button class="btn btn-primary" type="button">
                    <i class="fas fa-search me-2"></i>
                    </button>
                </div>
            </div>
        </form>
      </div>
    </div>

    <div class="container mt-5 mb-5">
        <div class="row">
            <div 
                align="center" 
                class=""
                v-if="loader"
                >
                <img src="../../../assets/spinner.gif" width="80" alt="">
            </div>

            <div 
                class="col-sm-6 col-md-6 col-lg-4"
                v-for="(notes, index) in notesCirculaires" :key="index"
                v-if="!loader && notesCirculaires.length>0"
                >
                <div class="card">
                    <div class="card-header bg-primary-blue">
                        N.C N<sup>o</sup>0{{ notes.reference }} de l'année {{ notes.annee }}
                    </div>
                    <div class="card-body">
                        <blockquote class="blockquote mb-0">
                        <p  class="height">Objet: {{ notes.objet }}</p>
                        <footer class="">
                            <a
                                :href="`https://admin.asla-oph.net/public/public/storage/noteCirculaires/${notes.fichier}`" 
                                class="btn btn-secondary form-control"
                                download
                            >
                                <i class="fa-sharp fa-solid fa-file-pdf"></i> Télécharger
                            </a> 
<!-- 
                            <router-link 
                                :to="{ name: 'single-note-circulaire', query:{id: notes.id, fichier: notes.fichier.toLowerCase()}}"
                                class="btn btn-secondary form-control">
                                <i class="fa-sharp fa-solid fa-file-pdf"></i> Télécharger
                            </router-link>  -->
                        </footer>
                        </blockquote>
                    </div>
                </div>
            </div>

            <no-data v-if="!loader && notesCirculaires.length<=0"/>
        </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";
import NoData from "../../NoData.vue";
import axios from "axios";
// import pdf from 'vue-pdf';
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  components: {
    Navigation,
    Hero,
    Footer,
    NoData,
    // pdf,
    VuePdfEmbed
  },

  data(){
    return{
        min: 1980,
        max: new Date().getFullYear(),
        annee: [],
        yearModel: null,
        notesCirculaires: [],
        loader: false,
        currentPage: 0,
        pageCount: 0,
        counterNotes: null
    }
  },

  methods:{
    getDynamicYear(){
        for(let i = this.min; i<=this.max; i++){
            this.annee.push(i)
        }
    },

    async getCounter(){
        await axios.get('get-counter')
        .then(res=>{
            this.counterNotes = res.data.Notes
        })
    },

    getNotesCirculaires(){
        axios.get('notes-circulaires', {
            params:{
                annee: this.yearModel
            }
        })
        .then(res=>{
            this.notesCirculaires = res.data
            this.loader = false
        })
    }
  },

  mounted(){
    this.getDynamicYear()
    this.getCounter()
  },

  watch:{
    yearModel:{
        handler(_n,_o){
            this.loader = true
            this.getNotesCirculaires()
        }
    }
  }
};
</script>

<style scoped>
.bg-primary-blue{
   background-color: #07609a; 
   color: #fff
}

.card{
    height: 250px;
}

.height{
    height: 100px;
}


</style>

