<template>
  <div>
    <Navigation />
    <Hero :title="title" ComponentTitle="VDH/Blog" />

    <div class="mt-3" align="center">
      <router-link :to="{ name: 'articles-blog' }" class="btn btn-secondary">
        <i class="fa-solid fa-circle-chevron-left"></i> Revenir
      </router-link>
    </div>

    <div align="center" class="" v-if="loader">
      <img src="../../../assets/spinner.gif" width="80" alt="" />
    </div>

    <div
      class="p-3 shadow-sm index-forward border-top"
      id=""
      v-for="(article, index) in singleArticle"
      :key="index"
      v-if="!loader"
    >
      <div class="">
        <div class="card">
          <div class="card-header bg-primary-blue">
            Titre: {{ article.title }}
          </div>

          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <i class="far fa-clock"></i>&nbsp;
                  <span
                    ><timeago :datetime="article.date_time_pub" auto-update
                  /></span>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <span
                    ><i class="fa-regular fa-comments"></i>
                    {{ counter.comments }} {{ counter.comments > 1 ? 'commentaires' : 'commentaire' }}</span
                  >
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <span
                    ><i class="fa-regular fa-eye"></i>
                    {{ counter.views }} {{ counter.views > 1 ? 'vues': 'vue' }}</span
                  >
                </div>
                <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3">
                  <!-- <span><i class="fa-regular fa-thumbs-up"></i> 4 likes</span> -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <blockquote class="blockquote mb-0">
              <p
                class="height"
                style="font-size: 17px"
                v-html="article.content"
              ></p>
            </blockquote>
          </div>

          <div class="card-footer">
            <div class="bloc-card-footer row">
              <!-- <div class="col-sm-8">
                Avez-vous aimé cet article ?
                <a href="" class="text-white"
                  ><u
                    >Cliquez ici et
                    <i class="fa-regular fa-thumbs-up"></i> likez</u
                  ></a
                >
              </div> -->
              <div class="text-card-footer col-sm-">
                <i class="fa-solid fa-pencil"></i>Par
                {{ article.user.first_name + " " + article.user.name }}
              </div>
            </div>
          </div>
        </div>

        <section class="bg-light">
          <div class=" mt">
            <div class="row gy-5">
              <div class="col-sm-8">
                <div class="card border-0 shadow-sm">
                  <div class="card-body p-5 border-top border-primary border-2">
                    <alert v-if="successSubmit != null" style="background-color: green; color: #fff; padding: 5px; border-radius: 2px">
                        {{ successSubmit }}
                    </alert>

                    <alert v-if="errorSubmit != null" style="background-color: darkred; color: #fff; padding: 5px; border-radius: 2px">
                        {{ errorSubmit }}
                    </alert>

                    <alert v-if="emptyInput != null" style="background-color: darkred; color: #fff; padding: 5px; border-radius: 2px">
                        {{ emptyInput }}
                    </alert>

                    <h2 class="mt-2">
                      <span class="text-primary lined"
                        >Laissez un commentaire</span
                      >
                    </h2>

                    <form
                      @submit.prevent="postComment()"
                      class="make-appointment-form"
                    >
                      <div class="row gy-4">
                        <div class="">
                          <input
                            class="form-control"
                            type="text"
                            placeholder="Nom ou pseudo"
                            v-model="commentaire.user_pseudo"
                          />
                        </div>

                        <div class="col-lg-12">
                          <textarea
                            class="form-control"
                            v-model="commentaire.comment"
                            rows="5"
                            placeholder="Votre commentaire ici"
                          ></textarea>
                        </div>
                        <div class="col-lg-12">
                          <button class="btn btn-primary w-100" type="submit">
                            Envoyer le commentaire
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- align-items-center -->
              <div class="col-sm-4">
                <div class="card">
                  <div
                    class="card-header text-center text-uppercase bg-primary-red"
                  >
                    <strong>Les articles les plus lus</strong>
                  </div>

                  <div class="card-body" v-for="(item, index) in popularArticle" :key="index">
                   <div class="card">
                    <div class="card-header" style="background-color: #07609a; padding: 5px; color: #fff">
                       <p>{{ item.title }}</p>
                    </div>

                    <div class="card-body">
                        <i class="fa-solid fa-eye"></i> {{ item.views_count }} {{ item.views_count > 1 ? 'vues' : 'vue'}}
                    </div>

                    <div class="">
                        <a :href="$router.resolve({name: 'single-article', query: {
                          article: item.id,
                          titre: item.slug.toLowerCase(),
                        }}).href"
                       class="btn text-white bg-info form-control">
                           <i class="fa-solid fa-eye"></i> Découvrir
                        </a>
                    </div>
                   </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="container-fluid">
        <div class="card">
          <div class="card-header bg-primary-red">Bloc de Commentaires</div>
          <div class="card-body">
            <div class="row mb-5" v-for="(comment, key) in comments" :key="key">
              <div class="col-sm-2">
                <div align="center">
                  <img src="../../../assets/img/user.png" width="50" alt="" v-if="comment.user_pseudo != 'ASLA-OPH'"/>

                  <img src="../../../assets/img/LogoAsla.png" width="120" class="img-thumbnail" style="" alt="" v-if="comment.user_pseudo == 'ASLA-OPH'" />

                  <p style="text-transform: capitalize" v-if="comment.user_pseudo != 'ASLA-OPH'">
                    {{ comment.user_pseudo }}
                  </p>
                </div>
              </div>
              <div class="col-sm-10">
                <p class="comment-justify">
                  {{ comment.comment }}
                </p>

                <p>
                  <span class="comment_ref"
                    ><timeago :datetime="comment.created_at" auto-update
                  /></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

  <script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";
import axios from "axios";

import Swal from "sweetalert2";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
  },

  data() {
    return {
      slug: this.$route.query.titre,
      id: this.$route.query.article,
      title: "",
      content: "",
      singleArticle: [],
      loader: true,

      commentaire: {
        user_pseudo: "",
        comment: "",
      },

      comments: [],
      counter: {
        comments: null,
        views: null,
      },
      successSubmit: null,
      errorSubmit: null,
      emptyInput: null,
      interactifArticle: [],
      popularArticle: []
    };
  },

  mounted() {
    axios
      .get("blog/single-article", {
        params: {
          id: this.id,
        },
      })
      .then((res) => {
        this.singleArticle = res.data;
        this.title = res.data[0].title;
        this.loader = false;
      });
    this.getComments();
    this.getCounter();
    this.insertView();
    this.getInteractifArticle()
    this.getPopularArticle()

  },

  methods: {
    async postComment() {
      if (!this.commentaire.user_pseudo || !this.commentaire.comment) {
        // this.emptyInput = "Champs obligatoires"
        Swal.fire({
            position: "center",
          icon: "error",
          title: "Oops...",
          text: "Ces 2 champs du commentaire sont requis",
        });
      } else {
        await axios
          .post("blog/comment", {
            id: this.id,
            user_pseudo: this.commentaire.user_pseudo,
            comment: this.commentaire.comment,
          })
          .then(() => {
            this.successSubmit = "Commentaire encours d'analyse"
            Swal.fire({
              icon: "success",
              title: "Bravo...",
              text: "Commentaire encours d'analyse",
            });

            
            this.commentaire = "";
          })
          .catch(() => {
            // this.errorSubmit = "Une erreur s'est produite, veuillez réessayer"
            Swal.fire({
              icon: "error",
              title: "Erreur...",
              text: "Une erreur s'est produite, veuillez réessayer",
            });
          });
      }
    },

    async insertView() {
      await axios.post("create-view-article", {
        article_id: this.id,
      })
      .then(()=>{
        console.log("Vue insérée success")
      })
    },

    async getComments() {
      await axios
        .get("blog/comments", {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          this.comments = res.data;
        });
    },

    async getCounter() {
      axios
        .get("get-counter-comment", {
          params: {
            article_id: this.id,
          },
        })
        .then((res) => {
          this.counter.comments = res.data.Comments;
        });

      axios
        .get("get-counter-view", {
          params: {
            article_id: this.id,
          },
        })
        .then((res) => {
          this.counter.views = res.data.Views;
        });
    },

    async getInteractifArticle(){
        await axios.get('blog/interactive-article')
        .then(res=>{
            this.interactifArticle = res.data
        })
    },

    async getPopularArticle(){
        await axios.get('blog/popular-article')
        .then(res=>{
            this.popularArticle = res.data
        })
    },
  },
};
</script>

<style scoped>
.bg-primary-blue {
  background-color: #07609a;
  color: #fff;
}

.card-footer {
  background-color: #8f4f4a;
}

.bloc-card-footer div {
  padding: 2px;
  color: #fff;
  border-radius: 10px;
  float: left;
  clear: none;
}

.bloc-card-footer-bis {
}

.text-card-footer {
  font-style: italic;
}

.mt {
  margin-top: -100px;
}

.bg-primary-red {
  background-color: #8f4f4a;
  color: #fff;
  /* margin-top: -50px; */
}

.comment-justify {
  /* text-align: justify; */
}

.comment_ref {
  background-color: #fff;
  border-radius: 2px;
  border: 2px solid #fff;
  box-shadow: 2px 2px 2px 2px;
  padding: 2px;
  float: right;
}

/* Perso */

/* End perso */
</style>