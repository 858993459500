<template>
  <div>
    <Navigation />
    <Hero 
        title="Sites"
        blueTitle="Internationaux"
        ComponentTitle="Sites"
        subTitle="Découvrez les sites internationaux de l'ASLA-OPH" 
        />

    <div class="p-3 shadow-sm index-forward border-top" id="">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6 col-md-4">
            <div class="card">
              <div class="card-header text-uppercase bg-primary-blue">
                MONt Kari-kari
              </div>
              <div class="card-body">
                <p class="">
                  <strong>Nom du site:</strong> Mont Kari Kari <br />
                  <strong>Date d'ouverture:</strong> 23 décembre 1995 <br />
                  <strong>Localisation:</strong> Congo-Brazzaville, département
                  du Pool, district de Goma Tse Tse, Kibossi Gare CFCO, 38 km de
                  Brazzaville
                </p>
              </div>

              <div class="card-footer">
                <router-link to="" class="btn btn-secondary form-control">
                  <i class="fa-solid fa-eye"></i> Portée spirituelle
                </router-link>
              </div>
            </div>
          </div>

          <br>
          <div class="col-sm-6 col-md-4">
            <div class="card">
              <div class="card-header text-uppercase bg-primary-blue">
                MONt des oliviers
              </div>
              <div class="card-body">
                <p class="">
                    <strong>Nom du site:</strong> Mont des Oliviers <br>
                    <strong>Date d'ouverture:</strong> Octobre 2003 <br>
                    <strong>Localisation:</strong> Congo-Brazzaville, département de la Bouenza, Communauté urbaine de Loudima Gare CFCO
                </p>
              </div>

              <div class="card-footer">
                <router-link :to="{name: 'mont-oliviers'}" class="btn btn-secondary form-control">
                  <i class="fa-solid fa-eye"></i> Portée spirituelle
                </router-link>
              </div>
            </div>
          </div>

          <br>
            <div class="col-sm-6 col-md-4">
            <div class="card">
              <div class="card-header text-uppercase bg-primary-blue">
                MONt bouyengui
              </div>
              <div class="card-body">
                <p class="">
                    <strong>Nom du site:</strong> Mont Bouyengui, Site de Béthanie (La Porte du Ciel) <br>
                    <strong>Date d'ouverture:</strong> 2007 <br>
                    <strong>Localisation:</strong> Congo-Brazzaville, département de Brazzaville, Arrondissement 8 Madibou
                </p>
              </div>

              <div class="card-footer">
                <router-link :to="{name: 'mont-bouyengui'}" class="btn btn-secondary form-control">
                  <i class="fa-solid fa-eye"></i> Portée spirituelle
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
            <div class="col-sm-6 col-md-4">
                <div class="card">
                <div class="card-header text-uppercase bg-primary-blue">
                    Makaka
                </div>
                <div class="card-body">
                    <p class="">
                        <strong>Nom du site:</strong> Nouvelle Béthléem de la 4<sup>e</sup> Génération de Dieu le Père <br>
                        <strong>Date d'ouverture:</strong> 2010 <br>
                        <strong>Localisation:</strong> Congo-Brazzaville, département du Pool, district de Goma Tse Tse,
                        Kibossi Gare CFCO, 30 km de Brazzaville
                    </p>
                </div>

                <div class="card-footer">
                    <router-link :to="{name: 'makaka'}" class="btn btn-secondary form-control">
                    <i class="fa-solid fa-eye"></i> Portée spirituelle
                    </router-link>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navigation from "../../Navigation.vue";
import Footer from "../../Footer.vue";
import Hero from "../../Hero.vue";

export default {
  components: {
    Navigation,
    Hero,
    Footer,
  },
};
</script>

<style scoped>
.bg-primary-blue {
  background-color: #07609a;
  color: #fff;
}
</style>